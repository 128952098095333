<template>
  <div id="app"  v-wechat-title="$route.meta.title">
    <router-view  style="height:calc(100% - 26px);background-color: #1C1D22;">
  </router-view>
  <div v-if="show" @click="jump()" style="color:#cccccc;font-size: 12px;background:rgb(28 29 34);padding:5px;display: flex;justify-content: center;cursor: pointer;">上海酷索网络科技有限公司，备案号:沪ICP备19045921号</div>

  </div>
  
</template>

<script>
//  import api from './api/api';
export default {
  name: 'App',
  components: {
 
  },
  
  data(){
    return {
      show:true,
      
    }
  },  
  setup(){
  
  },
  created() {
    if(window.location.href.includes('openask')){
      this.show = false
    }
    console.log(window.location.href,777)
    
  },
  mounted() {
    
  },
  methods:{
    jump(){
      window.open("https://beian.miit.gov.cn/") 
    }
   
  }
}
</script>

<style lang="scss">

</style>
