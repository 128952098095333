import { createApp } from 'vue'
import App from './App.vue'
import i18n from './lang/index';
// 此段代码写入main.js中
import axios from './utils/request.js'

import ElementPlus from 'element-plus'


import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/atom-one-dark.css'

import 'element-plus/dist/index.css'

import VueWechatTitle from 'vue-wechat-title'//动态修改title

import router from './router/index'
createApp(App).use(i18n).use(VueHighlightJS).use(ElementPlus).use(axios).use(router).use(VueWechatTitle).mount('#app')

