<template>
  <!-- 中间边菜单 -->
  <div :class="!ismenu?'midtopmenu':'openmidtopmenu'" >

      <!-- 文字聊天 -->
      <div v-if="type_task=='chat'" class="midtopdiv ground_cB8B8B8"  style="position: relative;height:100%;    display: flex;flex-direction: row;">
        <div  style="position: relative;width: inherit;">
           <!-- 上方菜单 -->
           <div  class="topborder" style="height:60px;border-bottom: 1px solid #323839">
            <div style="display:flex;    justify-content: space-between;height: 100%;align-items: center;">
              <div style="display:flex;font-size: 12px;margin-left: 15px;align-items: center;">
                <img v-if="!ismenu" src="../assets/nomore.svg" alt=""  @click="changemenu" style="width:18px;height:16px;cursor: pointer;">
                <div style="display:flex;    align-items: center;font-size: 12px;margin-left: 15px;">
                  <div class="font_c333333" style="letter-spacing:1px;font-size: 16px;font-weight: 900;">{{prompts_title}}</div>
                  <el-select v-if='chatrobtype ==1' style="margin-left:30px;  border-radius: 20px;  width: 100px;" class="selectgpt" v-model="gpttype" placeholder="请选择" @change="changegptype(gpttype)">
                    <el-option v-for="(item,index) in gtptypelist" :key="index" :label="item.name" :value="item.model"> </el-option>
                  </el-select>
                </div>
              </div>
              <div style="display:flex;align-items: center;margin-right: 20px">
                <button class="newchatbtn" @click="addnewchat" ><img src="../assets/chat_list_icon.svg" alt="" style="width:16px;height:16px;margin: 0 4px;"  >新建聊天</button>
                </div>
            </div>
          </div>
          <!-- 下面内容 -->
          <div class="phonediv" style="display:flex;flex-direction: column;align-items: center;height:80%;overflow-y: visible;">
            <!-- 聊天div -->
            <div v-if="ischatcontent" class="phonechatdiv scrollbardiv"  ref="itemBox" >
              <div v-for="(item,index) in chat_content" :key="index">
          
                <div v-if="(item.role == 'user' &&item.filename == '')||(item.role == 'user' &&!item.filename)"  class="userchatdiv">
                  <div style="">
                    <div  class="userchathtml">
                      <p style="text-align: justify;max-width:900px" v-html="item.content">
                      </p>
                    </div>           
                  </div>
                  <img :src="userinfo.avatar" alt="" class="chatusericon" >

                </div>
                <div  v-else-if="item.role == 'user'&&item.filename&&item.filename != '' ">
                  <div  class="userchatdiv">
                    <div style="">
                      <div class="userchathtml"  style="display:flex;min-height: 60px;">
                          <div style="display:flex; align-items: center;">
                            <img src="../assets/image/fileinput.svg" alt="" style="padding: 5px 5px;max-height:48px;    margin-right: 10px;">
                            <div>{{item.filename}}</div>
                          </div>
                        </div>      
                    </div>
                    <img :src="userinfo.avatar" alt="" class="chatusericon" >
                  </div>
                  <div  class="userchatdiv">
                    <div style="">
                      <div   class="userchathtml">
                        <p style="text-align: justify;max-width:900px" v-html="item.content">
                        </p>
                      </div>           
                    </div>
                    <img :src="userinfo.avatar" alt="" class="chatusericon" >
                  </div>
                </div>
                <div v-else-if="item.role == 'assistant'"   class="robortchatdiv">
                  <img :src="robotavart" alt="" class="chatusericon" >

                  <div style="">
                    <div class="robortchathtml">
                      <TextChat
                      style="max-width:900px"
                        ref="textRef"
                        :text="item.content"
                        :showCursor="false"
                      />

                    </div>
                  </div>
                </div>
                <div v-else style="padding-top:20px;padding-bottom:20px">
                  <el-divider content-position="center">以下对话是新内容</el-divider>
                </div>
              </div>          
              <div v-if="chat_loading" class="robortchatdiv">
                <img :src="robotavart" alt="" class="chatusericon" >
                  <div style="">
                    <div class="robortchathtml" style="min-height:28px">
                      <div v-if="text==''" style="display: flex;">
                      <div class="dot1"> </div>
                      <div class="dot2"></div>
                      <div class="dot3"></div>
                    </div>
                      <TextChat
                      v-else 
                        ref="textRef"
                        :text="text"
                        :showCursor="showCursor"
                      />
                    </div>
                  </div>
                </div>
            </div>          
          </div>
          <!-- 下方输入框 -->
          <div class="ground_cB8B8B8" style="width:100%;display:flex;height: 120px;flex-direction: column;justify-content: center;"> 
              <div v-if="schema != ''" style="background-color:#1f222a;padding: 0 3%;margin-bottom:10px">
                <div style="display:flex;">
                  <div style="width:74px"></div>
                  <div style="display:flex;border: 1px solid #454647;border-radius: 6px;    align-items: center;justify-content: space-between;">
                    <img src="../assets/image/fileinput.svg" alt="" style="width:28px;height:28px;margin-right:20px;padding: 5px 10px;">
                    <div style="font-size:16px;color:white">{{filenameinput}}</div>
                    <img src="../assets/image/closeinfo.svg" alt="" style="width:20px;height:20px;margin-left:20px;    padding: 3px 10px; cursor: pointer;" >
                  </div>
                </div>
              </div>
              <div style="display:flex;align-items: center;justify-content: center;padding: 0 3%;">
                <!-- <img src="../assets/image/speakinput.svg" alt="" style="width:40px;height:40px;margin-left:10px;margin-right:20px"> -->
                
                <el-input  maxlength="2000" v-model="userNowInput" @keyup.enter="chatrobtype==1?handleSubmit():chatrobtype ==2?GooglehandleSubmit():BinghandleSubmit()" class="w-50 m-2 inputask" :placeholder="t('midmenu.inputcontent')" style="margin-right: 8px;height:48px;border-radius: 12px;">
                  <template #prefix>
                    <el-upload
                        v-if="chatrobtype==1"
                        class="avatar-uploader"
                        action="https://chat.onechatai.cn/api/v1/file/uploadCreatSchema"
                        :show-file-list="false"
                        :on-success="inputfilesuccess"
                        :before-upload="beforefileUpload"
                        style="display:flex"
                      >
                        <img src="../assets/image/link.svg" alt="" style="width:24px;height:24px;margin-left:5px;margin-right:15px;cursor: pointer;" >
                    </el-upload>
                  </template>
                   
                </el-input>
                <img src="../assets/image/textsend.svg" alt="" style="width:40px;height:40px;margin-left:20px;margin-right:10px;cursor: pointer;" @click="chatrobtype==1?handleSubmit():chatrobtype ==2?GooglehandleSubmit():BinghandleSubmit()">
              </div>
          </div>
        </div>
        <div v-if="chathistoryshow" style="width:20px;    background-color: #1C1D22;" class="">
          
        </div>
        <div v-if="chathistoryshow" style="padding:20px;margin-bottom:40px;max-width: 400px;">
            
            <div style="color:white; ">
              历史记录  
            </div>
            <div v-if="ischatcontent" class="phonechatdiv scrollbardiv"  ref="newitemBox" style="height:100%">
              
              <div v-for="(item,index) in chat_content" :key="index">
                <div v-if="item.role == 'user'"  class="userchatdiv">
                  <div style="max-width: 200px;">
                    <div class="hisuserchathtml userchathtml">
                      <p style="text-align: justify;" v-html="item.content">
                      </p>
                    </div>
                  </div>
                  <img :src="userinfo.avatar" alt="" class="hischatusericon" >

                </div>
                <div v-else  class="robortchatdiv">
                  <img :src="robotavart" alt="" class="hischatusericon" >

                  <div style="max-width: 200px;">
                    <div class="hisrobortchathtml robortchathtml">
                      <TextChat
                        ref="textRef"
                        :text="item.content"
                        :showCursor="false"
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
           
        </div>
      </div>
      <!-- 图片 -->
      <div v-if="type_task=='image'" class="midtopdiv ground_cB8B8B8" style="    position: relative;display:flex">
        <div style="position: relative;width: inherit;">
              <!-- 上方菜单 -->
              <div  class="topborder" style="height:60px;border-bottom: 1px solid #323839;">
                <div style="display:flex;    justify-content: space-between;height: 100%;align-items: center;">
                  <div style="display:flex;font-size: 12px;margin-left: 15px;align-items: center;">
                    <img v-if="!ismenu" src="../assets/nomore.svg" alt=""  @click="changemenu" style="width:18px;height:16px;cursor: pointer;">
                    <div style="display:flex;flex-direction: column;font-size: 12px;margin-left: 15px;">
                      <div class="font_c333333" style="letter-spacing:1px">AI 绘画</div>
                      <div style="color:#1380FF;margin-top: 4px;">{{t('midmenu.topfont')}}</div>
                    </div>
                  </div>
                
                  <div style="display:flex;align-items: center;">

                    <button class="newchatbtn"   ><img src="../assets/chat_list_icon.svg" alt="" style="width:16px;height:16px;margin: 0 4px;"  >新建聊天</button>

                    <!-- <img v-if="!isdark" src="../assets/record.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px"  > -->
                    <!-- <img v-if="!isdark" src="../assets/share.svg" alt="" style="width:32px;height:28px;margin: 0 10px;"> -->
                    <!-- <img v-if="!isdark" src="../assets/thirdpoint.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px;"> -->
                    <!-- <img v-if="isdark" src="../assets/record.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px" > -->
                    <!-- <img v-if="isdark" src="../assets/darkpoint.svg" alt="" style="width:32px;height:28px;margin: 0 10px;"> -->
                    <!-- <img v-if="isdark" src="../assets/darkshare.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px;" @click="gotologin()"> -->

                  </div>
                </div>
              
              </div>
            <!-- 下面内容 -->
              <div class="phonediv" style="display:flex;flex-direction: column;align-items: center;height:82%;overflow-y: visible;">
                <!-- 聊天div -->
                <div v-if="ischatcontent" class="phonechatdiv scrollbardiv"  ref="itemBox" >
                  <div v-for="(item,index) in chat_content" :key="index">
                    <div v-if="item.role == 'user'"  class="userchatdiv">
                      <div>
                        <div class="userchathtml"  style="padding:12px 20px">
                          <p style="text-align: justify;" v-html="item.content">
                          </p>
                        </div>
                      </div>
                      <img :src="userinfo.avatar" alt="" class="chatusericon" >

                    </div>
                    <div v-else  class="robortchatdiv">
                      <img :src="robotavart" alt="" class="chatusericon" >

                      <div>
                        <div v-if="chat_loading&&index==chat_content.length-1" class="robortchathtml" style="display:flex;flex-direction: column;padding:12px 20px">
                          <div style="margin-bottom:10px">
                            准备中...
                          </div>
                          <div style="margin-bottom:20px;display:flex;      width: 280px;  flex-direction: column;">
                            任务进度,任务描述 {{ item.describe }}
                            <el-progress :percentage="percentage"></el-progress>
                          </div>
                          <div v-if="temp_image!=''" style="border-radius: 10px;">
                            <img :src="temp_image" alt="" style="width: 280px;height: 280px;border-radius: 10px;">
                          </div>
                        </div>
                        <div v-if="!chat_loading&&index==chat_content.length-1||index!=chat_content.length-1" class="robortchathtml" style="display:flex;    flex-direction: column;">
                          <div style="margin-bottom:20px">
                           {{ item.describe }}
                          </div>
                          <div style="border-radius: 10px;">
                            <img :src="item.image" alt="" style="width: 280px;height: 280px;border-radius: 10px;">
                          </div>
                          <div v-if="item.status" style="display:flex;flex-wrap: wrap;width: 280px;">
                            <img src="../assets/image/U1.svg" alt="" style="width: 46px;height:32px;margin-right: 10px;margin-bottom: 10px;margin-top: 10px;">
                            <img src="../assets/image/U2.svg" alt="" style="width: 46px;height:32px;margin-right: 10px;margin-bottom: 10px;margin-top: 10px;">
                            <img src="../assets/image/U3.svg" alt="" style="width: 46px;height:32px;margin-right: 10px;margin-bottom: 10px;margin-top: 10px;">
                            <img src="../assets/image/U4.svg" alt="" style="width: 46px;height:32px;margin-right: 10px;margin-bottom: 10px;margin-top: 10px;">
                            <img src="../assets/image/refresh.svg" alt="" style="width: 46px;height:32px;margin-bottom: 10px;margin-top: 10px;">
                            <img src="../assets/image/v1.svg" alt="" style="width: 46px;height:32px;margin-right: 10px;">
                            <img src="../assets/image/v2.svg" alt="" style="width: 46px;height:32px;margin-right: 10px;">
                            <img src="../assets/image/v3.svg" alt="" style="width: 46px;height:32px;margin-right: 10px;">
                            <img src="../assets/image/v4.svg" alt="" style="width: 46px;height:32px;margin-right: 10px;">
                          </div>
                        </div>
                      </div>

                      

                    </div>
                  </div>
                 
                </div>
              </div>
            <!-- 下方输入框 -->
              <div class="ground_cB8B8B8" style="position: absolute;width:100%;bottom: 20px;padding-top: 10px;"> 
                  <div style="display:flex;align-items: center;justify-content: center;">
                     <el-input v-model="userNowInput" @keyup.enter="imagehandleSubmit" class="w-50 m-2 inputask" :placeholder="t('midmenu.inputcontent')" style="margin-right: 8px;width: 45vw;height:48px;border-radius: 12px;"></el-input>
                     <img  src="../assets/image/searchinput.svg" alt="" style="width:40px;height:40px;margin-left:20px;margin-right:10px;cursor: pointer;" @click="imagehandleSubmit()">
                  </div>
              </div>
        </div>
        <div style="width:20px;    background-color: #1C1D22;" class="">
          
        </div>
        <div style="width:500px;height:100vh;padding:20px">
            <div style="width:460px;border-radius: 12px;padding:10px;background-color: #181A20;">
              <el-upload
                class="addphoto"
                :file-list="picfileList"
                action="/api/v1/chat/imageGetUrl"
                list-type="picture-card"
                :before-upload="beforeAvatarUpload"
                :on-success="pichandleAvatarSuccess"
                :limit="2"
                :on-remove="pichandleRemove"
                :on-preview="handlehuituPictureCardPreview"

              >
                <el-icon><img src="../assets/image/addphoto.svg" alt="" style="width:20px;height:20px"></el-icon>
              </el-upload>
              <el-dialog v-model="picdialogVisible"  align-center :show-close="false" style="width:600px">
                <img w-full :src="picdialogImageUrl" style="width:100%;height:100%;margin-bottom:20px" alt="Preview Image" />
              </el-dialog>
            </div>
            <div style="margin-top:24px;color:white;display: flex;align-items: flex-end;">
              尺寸 <div style="margin-left:5px;font-size:12px">可在输入框自行输入尺寸</div>
            </div>
            <div style="margin-top:17px;display: flex;flex-wrap: wrap;justify-content: space-between;">
              
              <div class="imagesize" :style="imageindex==1?{'border':'1px solid #006FF9'}:{'border':'1px solid #3E4447'}" @click="selectimagesize(1)">
                <img src="../assets/image/no11.svg" alt="">
              </div>
              <div class="imagesize" :style="imageindex==2?{'border':'1px solid #006FF9'}:{'border':'1px solid #3E4447'}" @click="selectimagesize(2)">
                <img src="../assets/image/no12.svg" alt="">
              </div>
              <div class="imagesize" :style="imageindex==3?{'border':'1px solid #006FF9'}:{'border':'1px solid #3E4447'}" @click="selectimagesize(3)">
                <img src="../assets/image/no34.svg" alt="">
              </div>
              <div class="imagesize" :style="imageindex==4?{'border':'1px solid #006FF9','margin-top':'10px'}:{'border':'1px solid #3E4447','margin-top':'10px'}" @click="selectimagesize(4)">
                <img src="../assets/image/no342.svg" alt="">
              </div>
              <div class="imagesize" :style="imageindex==5?{'border':'1px solid #006FF9','margin-top':'10px'}:{'border':'1px solid #3E4447','margin-top':'10px'}" @click="selectimagesize(5)">
                <img src="../assets/image/no916.svg" alt="">
              </div>
               
              <div class="imagesize" :style="imageindex==6?{'border':'1px solid #006FF9;','margin-top':'10px'}:{'border':'1px solid #3E4447','margin-top':'10px'}" @click="selectimagesize(6)">
                <div>

                </div>
              </div>
            </div>
            <div style="margin-top:24px;color:white;display: flex;align-items: flex-end;">
              关键词推荐 <div style="margin-left:5px;font-size:12px">非必选</div>
            </div>
            <el-radio-group class="radioselect" v-model="promptWords" size="large" style="margin-top:20px" @change="changepromptWords()">
              <el-radio-button v-for="(item,index) in promptWordsList" :key="index" :label="item.value" >{{ item.text }}</el-radio-button>
              
            </el-radio-group>
            <div v-for="(item,index) in promptChlidList" :key="index" style="margin-top:24px;color:white;">
              <div style="display: flex;align-items: flex-end;cursor: pointer;" @click="item.select = !item.select">
                <img v-if="item.select" src="../assets/image/down_arrow.svg" alt="" style="width:24px;height:24px;margin-right: 5px;"> 
                <img v-else src="../assets/image/right_arrow.svg" alt="" style="width:24px;height:24px;margin-right: 5px;"> 
                {{item.text}}（{{item.chlidren.length}}） 
              </div>
              <div v-if="item.select" style="display:flex;    flex-wrap: wrap;margin-top:5px">
                <div v-for="(obj,objindex) in item.chlidren" :key="objindex" >
                  <div v-if="!obj.select" style="border:1px solid #1380FF; padding:3px 10px;background-color: #181A20;
            border-radius: 12px;margin: 5px 6px;color:white;cursor: pointer;font-size: 12px;" @click="selectPrompt(obj)">{{ obj.value }} | {{ obj.text }}</div>
                    <div v-else style="padding:3px 10px;background-color: #1380FF;border-radius: 12px;cursor: pointer;;font-size: 12px;
                  margin: 5px 6px;color:white"  @click="cancelPrompt(obj)">{{ obj.value }} | {{ obj.text }} </div>
                 
                </div>
              
              </div>
            </div>
            <!-- <div style="margin-top:24px;color:white;display: flex;align-items: flex-end;">
              <img src="../assets/image/down_arrow.svg" alt="" style="width:24px;height:24px;margin-right: 5px;"> 
              画质（9） 
            </div> -->
          <div>

          </div>
        </div>
      </div>
      <!-- 语音 -->
      <div v-if="type_task=='voice'" class="midtopdiv ground_cB8B8B8"  style="position: relative;height:100%;    display: flex;flex-direction: row;">
        <div  style="position: relative;width: inherit;">
           <!-- 上方菜单 -->
           <div  class="topborder" style="height:60px;border-bottom: 1px solid #323839">
            <div style="display:flex;    justify-content: space-between;height: 100%;align-items: center;">
              <div style="display:flex;font-size: 12px;margin-left: 15px;align-items: center;">
                <img v-if="!ismenu" src="../assets/nomore.svg" alt=""  @click="changemenu" style="width:18px;height:16px;cursor: pointer;">
                <div style="display:flex;flex-direction: column;font-size: 12px;margin-left: 15px;">
                  <div class="font_c333333" style="letter-spacing:1px;font-size: 16px;font-weight: 900;">{{prompts_title}}</div>
                </div>
              </div>
             
              <div style="display:flex;align-items: center;">

                <button class="newchatbtn" @click="addnewchat" ><img src="../assets/chat_list_icon.svg" alt="" style="width:16px;height:16px;margin: 0 4px;"  >新建聊天</button>

                <!-- <img v-if="!isdark" src="../assets/record.svg" alt="" style="width:32px;height:28px;margin: 0 10px;cursor: pointer;margin-right: 20px" @click="showhistory"> -->
                <!-- <img v-if="!isdark" src="../assets/share.svg" alt="" style="width:32px;height:28px;margin: 0 10px;"> -->
                <!-- <img v-if="!isdark" src="../assets/thirdpoint.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px;"> -->
                <!-- <img v-if="isdark" src="../assets/record.svg" alt="" style="width:32px;height:28px;margin: 0 10px;cursor: pointer;margin-right: 20px" @click="showhistory"> -->
                <!-- <img v-if="isdark" src="../assets/darkpoint.svg" alt="" style="width:32px;height:28px;margin: 0 10px;"> -->
                <!-- <img v-if="isdark" src="../assets/darkshare.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px;" @click="gotologin()"> -->

              </div>
            </div>
           
          </div>
          <!-- 下面内容 -->
          <div class="phonediv" style="display:flex;flex-direction: column;align-items: center;height:calc(83% - 150px);;overflow-y: visible;">
            <!-- 聊天div -->
            <div v-if="ischatcontent" class="phonechatdiv scrollbardiv"  ref="itemBox" >
              
              <div v-for="(item,index) in chat_content" :key="index">
                <div v-if="item.role == 'user'&&item.voiceUrl != ''"  class="userchatdiv">
                  <Speechvue :file="item"></Speechvue>
                  <img :src="userinfo.avatar" alt="" class="chatusericon" >

                </div>
                <div v-else-if="item.role == 'user'&&item.voiceUrl == ''" class="userchatdiv">
                    <div style="">
                      <div   class="userchathtml">
                        <p style="text-align: justify;" v-html="item.content">
                        </p>
                      </div>           
                    </div>
                    <img :src="userinfo.avatar" alt="" class="chatusericon" >
                </div>
                <div v-else-if="item.role == 'assistant'"   class="robortchatdiv">
                  <img :src="robotavart" alt="" class="chatusericon" >
                  <Speechvuerob :file="item" ></Speechvuerob>

                </div>
                <div v-else style="padding-top:20px;padding-bottom:20px">
                  <el-divider content-position="center">以下对话是新内容</el-divider>
                </div>
              </div>
           
              <div v-if="chat_loading" class="robortchatdiv">
                <img :src="robotavart" alt="" class="chatusericon" >

                  <div style="">
                    <div class="robortchathtml" style="min-height:28px">
                      <div  style="display: flex;">
                      <div class="dot1"> </div>
                      <div class="dot2"></div>
                      <div class="dot3"></div>
                    </div>
                   
                    </div>
                  </div>

                </div>
            </div>
           

          </div>
          <!-- 下方输入框 -->
          <div class="ground_cB8B8B8" style="position: absolute;width:calc(100% - 40px);margin:0 20px;border-radius:10px;bottom: 20px; 
              display: flex;justify-content: center;flex-direction: column;background-color: #2A2D36 !important;height:150px"> 
            <AudioRecorder v-if="openRecorder" :status="talkstatus"></AudioRecorder>

            <div v-if="!voiceuseinput" style="display:flex;justify-content: center;align-items: center;">
              <img   src="../assets/image/keyword.svg" alt="" style="width: 36px;height: 36px;position: absolute;left: 40px;cursor: pointer;" @click="voiceuseinput = true">

              <img   src="../assets/image/senddelete.svg" alt="" style="width:20px;height:20px;margin-right: 15px;cursor: pointer;" @click="voiceDestroy()">
              <img v-if="!openRecorder" src="../assets/image/sendopen.svg" alt="" style="width:32px;height:32px;margin-right:15px;cursor: pointer;" @click="startRecorder">
              <img v-if="openRecorder&&isvoicestop" src="../assets/image/sendopen.svg" alt="" style="width:32px;height:32px;margin-right:15px;cursor: pointer;" @click="handleResumePlay">
              <img v-if="openRecorder&&!isvoicestop" src="../assets/image/sendend.svg" alt="" style="width:32px;height:32px;margin-right:15px;cursor: pointer;" @click="stopPlayRecorder">
              <img src="../assets/image/sendspeech.svg" alt="" style="width:20px;height:20px;cursor: pointer;" @click="sendvoice()">
            </div>

            <div v-if="voiceuseinput" style="display:flex;align-items: center;justify-content: center;padding: 0 30px;">
                <img src="../assets/image/speakto.svg" alt="" style="width:36px;height:36px;margin-left:10px;margin-right:20px;cursor: pointer;"  @click="voiceuseinput = false">
                <el-input   maxlength="2000" v-model="userNowInput" @keyup.enter="sendvoice" class="w-50 m-2 inputask" :placeholder="t('midmenu.inputcontent')" style="margin-right: 8px;height:48px;border-radius: 12px;"></el-input>
                <!-- <img src="../assets/image/speakinput2.svg" alt="" style="width:40px;height:40px;margin-left:20px;margin-right:10px" @click="endRecognition"> -->
                <img src="../assets/image/textsend.svg" alt="" style="width:40px;height:40px;margin-left:20px;margin-right:10px;cursor: pointer;" @click="sendvoice()">
              </div>
          </div>
        </div>
        <div v-if="chathistoryshow" style="width:20px;   background-color: #1C1D22;" class="">
           
        </div>
        <div v-if="chathistoryshow" style="padding:20px;margin-bottom:40px;max-width: 400px;">
            
            <div style="color:white; ">
              历史记录  
            </div>
            <div v-if="ischatcontent" class="phonechatdiv scrollbardiv"  ref="newitemBox" style="height:100%">
              
              <div v-for="(item,index) in chat_content" :key="index">
                <div v-if="item.role == 'user'"  class="userchatdiv">
                  <div style="max-width: 200px;">
                    <div class="hisuserchathtml userchathtml">
                      <p style="text-align: justify;" v-html="item.content">
                      </p>
                    </div>
                    
                    <!-- <div class="chatbottomicondiv">
                        <img src="../assets/chatrefreshicon.svg" alt="" class="chatbottomicon">
                        <img src="../assets/chatcopyicon.svg" alt="" class="chatbottomicon">
                        <img src="../assets/chatdeleteicon.svg" alt="" class="chatbottomicon">
                    </div> -->
                  </div>
                  <img :src="userinfo.avatar" alt="" class="hischatusericon" >

                </div>
                <div v-else  class="robortchatdiv">
                  <img :src="robotavart" alt="" class="hischatusericon" >

                  <div style="max-width: 200px;">
                    <div class="hisrobortchathtml robortchathtml">
                      <TextChat
                        ref="textRef"
                        :text="item.content"
                        :showCursor="false"
                      />

                    </div>

                    <!-- <div class="chatrobbottomicondiv">
                        <img src="../assets/chatrefreshicon.svg" alt="" class="chatbottomicon">
                        <img src="../assets/chatcopyicon.svg" alt="" class="chatbottomicon">
                        <img src="../assets/chatdeleteicon.svg" alt="" class="chatbottomicon">
                    </div> -->
                  </div>

                </div>
              </div>
            </div>
           
        </div>
      </div>
      <!-- 图文分析 -->
      <div v-if="type_task=='chatnews'" class="midtopdiv ground_cB8B8B8"  style="position: relative;height:100%;    display: flex;flex-direction: row;">
        <div  style="position: relative;width: inherit;">
           <!-- 上方菜单 -->
           <div  class="topborder" style="height:60px;border-bottom: 1px solid #323839">
            <div style="display:flex;    justify-content: space-between;height: 100%;align-items: center;">
              <div style="display:flex;font-size: 12px;margin-left: 15px;align-items: center;">
                <img v-if="!ismenu" src="../assets/nomore.svg" alt=""  @click="changemenu" style="width:18px;height:16px;cursor: pointer;">
                <div style="display:flex;    align-items: center;font-size: 12px;margin-left: 15px;">
                  <div class="font_c333333" style="letter-spacing:1px;font-size: 16px;font-weight: 900;">{{prompts_title}}</div>
                 
                </div>
              </div>
             
              <div style="display:flex;align-items: center;">
                <button class="newchatbtn" @click="addnewchat" ><img src="../assets/chat_list_icon.svg" alt="" style="width:16px;height:16px;margin: 0 4px;"  >新建聊天</button>
                <!-- <img v-if="!isdark" src="../assets/record.svg" alt="" style="width:32px;height:28px;margin: 0 10px;cursor: pointer;margin-right: 20px" @click="showhistory"> -->
                <!-- <img v-if="!isdark" src="../assets/share.svg" alt="" style="width:32px;height:28px;margin: 0 10px;"> -->
                <!-- <img v-if="!isdark" src="../assets/thirdpoint.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px;"> -->
                <!-- <img v-if="isdark" src="../assets/record.svg" alt="" style="width:32px;height:28px;margin: 0 10px;cursor: pointer;margin-right: 20px" @click="showhistory"> -->
                <!-- <img v-if="isdark" src="../assets/darkpoint.svg" alt="" style="width:32px;height:28px;margin: 0 10px;"> -->
                <!-- <img v-if="isdark" src="../assets/darkshare.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px;" @click="gotologin()"> -->

              </div>
            </div>
           
          </div>
          <!-- 下面内容 -->
          <div class="phonediv" style="display:flex;flex-direction: column;align-items: center;height:80%;overflow-y: visible;">
            <!-- 聊天div -->
            <div v-if="ischatcontent" class="phonechatdiv scrollbardiv"  ref="itemBox" >
              
              <div v-for="(item,index) in chat_content" :key="index">
                <div v-if="item.role == 'user'"  class="userchatdiv">
                  <div style="">
                    <div v-for="(obj,index) in item.content" :key="index" class="userchathtml" style="margin-bottom: 10px">
                      <div  v-if="obj.type == 'text'">
                        <p style="text-align: justify;" v-html="obj.text">
                        </p>
                      </div>
                      <div v-else style="display:flex;height:300px">
                        <div style="display:flex; ">
                          <img :src="obj.image_url.url" alt="" style="padding: 5px 5px;max-height:300px">
                        </div>
                      </div>
                    </div>
                  </div>
                  <img :src="userinfo.avatar" alt="" class="chatusericon" >

                </div>
                <div v-else-if="item.role == 'assistant'"   class="robortchatdiv">
                  <img :src="robotavart" alt="" class="chatusericon" >

                  <div style="">
                    <div class="robortchathtml">
                      <TextChat
                        ref="textRef"
                        :text="item.content"
                        :showCursor="false"
                      />
                    </div>
                  </div>
                </div>
                <div v-else style="padding-top:20px;padding-bottom:20px">
                  <el-divider content-position="center">以下对话是新内容</el-divider>
                </div>
              </div>
              <div v-if="chat_loading" class="robortchatdiv">
                <img :src="robotavart" alt="" class="chatusericon" >
                  <div style="">
                    <div class="robortchathtml" style="min-height:28px">
                      <div v-if="text==''" style="display: flex;">
                      <div class="dot1"> </div>
                      <div class="dot2"></div>
                      <div class="dot3"></div>
                    </div>
                      <TextChat
                      v-else 
                        ref="textRef"
                        :text="text"
                        :showCursor="showCursor"
                      />
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- 下方输入框 -->
          <div class="ground_cB8B8B8" style="width:100%;display:flex;height: 120px;flex-direction: column;justify-content: center;"> 
              <div v-if="userNowInputUrl.length != 0" style="background-color:#1f222a;padding: 0 3%;margin-bottom:10px">
                <div style="display:flex;">
                  <div style="width:54px"></div>
                  <div v-for="(item,index) in userNowInputUrl" :key="index"  style="margin-left:20px;display:flex;border: 1px solid #454647;border-radius: 6px;    align-items: center;justify-content: space-between;">
                    <img :src="item" alt="" style="width:28px;height:28px;padding: 5px 10px;">
                    <img src="../assets/image/closeinfo.svg" alt="" style="width:20px;height:20px;padding: 3px 10px; cursor: pointer;" @click="urldelete(index)">
                  </div>
                </div>
              </div>
              <div style="display:flex;align-items: center;justify-content: center;padding: 0 3%;">
                <img src="../assets/image/speakinput.svg" alt="" style="width:40px;height:40px;margin-left:10px;margin-right:20px">
                <el-input  maxlength="2000" v-model="userNowInput" @keyup.enter="handleChatNewsSubmit" class="w-50 m-2 inputask" :placeholder="t('midmenu.inputcontent')" style="margin-right: 8px;height:48px;border-radius: 12px;">
                  <template #prefix>
                    <el-upload
                        class="avatar-uploader"
                        action="https://chat.onechatai.cn/api/v1/file/fileGetUrl"
                        :show-file-list="false"
                        :on-success="filesuccessurl"
                        :before-upload="beforeAvatarUpload"
                        style="display:flex"
                      >
                        <img src="../assets/image/link.svg" alt="" style="width:24px;height:24px;margin-left:5px;margin-right:15px;cursor: pointer;" >
                    </el-upload>
                  </template>
                   
                </el-input>
                <img src="../assets/image/textsend.svg" alt="" style="width:40px;height:40px;margin-left:20px;margin-right:10px;cursor: pointer;" @click="handleChatNewsSubmit()">
              </div>
          </div>
        </div>
        <div v-if="chathistoryshow" style="width:20px;    background-color: #1C1D22;" class="">
          
        </div>
        <div v-if="chathistoryshow" style="padding:20px;margin-bottom:40px;max-width: 400px;">
            
            <div style="color:white; ">
              历史记录  
            </div>
            <div v-if="ischatcontent" class="phonechatdiv scrollbardiv"  ref="newitemBox" style="height:100%">
              
              <div v-for="(item,index) in chat_content" :key="index">
                <div v-if="item.role == 'user'"  class="userchatdiv">
                  <div style="max-width: 200px;">
                    <div class="hisuserchathtml userchathtml">
                      <p style="text-align: justify;" v-html="item.content">
                      </p>
                    </div>
                  </div>
                  <img :src="userinfo.avatar" alt="" class="hischatusericon" >
                </div>
                <div v-else  class="robortchatdiv">
                  <img :src="robotavart" alt="" class="hischatusericon" >
                  <div style="max-width: 200px;">
                    <div class="hisrobortchathtml robortchathtml">
                      <TextChat
                        ref="textRef"
                        :text="item.content"
                        :showCursor="false"
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
           
        </div>
      </div>

       <!-- 生成图片 -->
       <div v-if="type_task=='creatimage'" class="midtopdiv ground_cB8B8B8"  style="position: relative;height:100%;    display: flex;flex-direction: row;">
        <div  style="position: relative;width: inherit;">
           <!-- 上方菜单 -->
           <div  class="topborder" style="height:60px;border-bottom: 1px solid #323839">
            <div style="display:flex;    justify-content: space-between;height: 100%;align-items: center;">
              <div style="display:flex;font-size: 12px;margin-left: 15px;align-items: center;">
                <img v-if="!ismenu" src="../assets/nomore.svg" alt=""  @click="changemenu" style="width:18px;height:16px;cursor: pointer;">
                <div style="display:flex;    align-items: center;font-size: 12px;margin-left: 15px;">
                  <div class="font_c333333" style="letter-spacing:1px;font-size: 16px;font-weight: 900;">{{prompts_title}}</div>
                  
                </div>
              </div>
             
              <div style="display:flex;align-items: center;">

                <button class="newchatbtn" @click="addnewchat" ><img src="../assets/chat_list_icon.svg" alt="" style="width:16px;height:16px;margin: 0 4px;"  >新建聊天</button>

                <!-- <img v-if="!isdark" src="../assets/record.svg" alt="" style="width:32px;height:28px;margin: 0 10px;cursor: pointer;margin-right: 20px" @click="showhistory"> -->
                <!-- <img v-if="!isdark" src="../assets/share.svg" alt="" style="width:32px;height:28px;margin: 0 10px;"> -->
                <!-- <img v-if="!isdark" src="../assets/thirdpoint.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px;"> -->
                <!-- <img v-if="isdark" src="../assets/record.svg" alt="" style="width:32px;height:28px;margin: 0 10px;cursor: pointer;margin-right: 20px" @click="showhistory"> -->
                <!-- <img v-if="isdark" src="../assets/darkpoint.svg" alt="" style="width:32px;height:28px;margin: 0 10px;"> -->
                <!-- <img v-if="isdark" src="../assets/darkshare.svg" alt="" style="width:32px;height:28px;margin: 0 10px;margin-right: 20px;" @click="gotologin()"> -->

              </div>
            </div>
           
          </div>
          <!-- 下面内容 -->
          <div class="phonediv" style="display:flex;flex-direction: column;align-items: center;height:80%;overflow-y: visible;">
            <!-- 聊天div -->
            <div v-if="ischatcontent" class="phonechatdiv scrollbardiv"  ref="itemBox" >
              
              <div v-for="(item,index) in chat_content" :key="index">
                <div v-if="item.role == 'user'"  class="userchatdiv">
                  <div style="">
                    <div class="userchathtml">
                      <p style="text-align: justify;" v-html="item.content">
                      </p>
                    </div>
                  </div>
                  <img :src="userinfo.avatar" alt="" class="chatusericon" >
                </div>
                <div v-else-if="item.role == 'assistant'"   class="robortchatdiv">
                  <img :src="robotavart" alt="" class="chatusericon" >

                  <div style="">
                    <div v-for="(obj,index) in item.content" :key="index" class="robortchathtml" style="margin-bottom: 10px">
                      <div >
                        <p style="text-align: justify;" v-html="obj.revised_prompt">
                        </p>
                      </div>
                      <div>
                        <div style="display:flex; margin-top:30px;height:300px">
                          <img :src="obj.url" alt="" style="padding: 5px 5px;max-height:300px">
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div v-else style="padding-top:20px;padding-bottom:20px">
                  <el-divider content-position="center">以下对话是新内容</el-divider>
                </div>
              </div>
           
              <div v-if="chat_loading" class="robortchatdiv">
                <img :src="robotavart" alt="" class="chatusericon" >

                  <div style="">
                    <div class="robortchathtml" style="min-height:28px">
                      <div v-if="text==''" style="display: flex;">
                      <div class="dot1"> </div>
                      <div class="dot2"></div>
                      <div class="dot3"></div>
                    </div>
                      <TextChat
                      v-else 
                        ref="textRef"
                        :text="text"
                        :showCursor="showCursor"
                      />
                    </div>
                  </div>

                </div>
            </div>
           

          </div>
          <!-- 下方输入框 -->
          <div class="ground_cB8B8B8" style="width:100%;display:flex;height: 120px;flex-direction: column;justify-content: center;"> 
              
              <div style="display:flex;align-items: center;justify-content: center;padding: 0 3%;">
                <img src="../assets/image/speakinput.svg" alt="" style="width:40px;height:40px;margin-left:10px;margin-right:20px">
                <el-input  maxlength="2000" v-model="userNowInput" @keyup.enter="handleSubmitCreateImg" class="w-50 m-2 inputask" :placeholder="t('midmenu.inputcontent')" style="margin-right: 8px;height:48px;border-radius: 12px;">
                </el-input>
                <img src="../assets/image/textsend.svg" alt="" style="width:40px;height:40px;margin-left:20px;margin-right:10px;cursor: pointer;" @click="handleSubmitCreateImg()">
              </div>
          </div>
        </div>
        <div v-if="chathistoryshow" style="width:20px;    background-color: #1C1D22;" class="">
          
        </div>
        <div v-if="chathistoryshow" style="padding:20px;margin-bottom:40px;max-width: 400px;">
            
            <div style="color:white; ">
              历史记录  
            </div>
            <div v-if="ischatcontent" class="phonechatdiv scrollbardiv"  ref="newitemBox" style="height:100%">
              
              <div v-for="(item,index) in chat_content" :key="index">
                <div v-if="item.role == 'user'"  class="userchatdiv">
                  <div style="max-width: 200px;">
                    <div class="hisuserchathtml userchathtml">
                      <p style="text-align: justify;" v-html="item.content">
                      </p>
                    </div>
                  </div>
                  <img :src="userinfo.avatar" alt="" class="hischatusericon" >

                </div>
                <div v-else  class="robortchatdiv">
                  <img :src="robotavart" alt="" class="hischatusericon" >

                  <div style="max-width: 200px;">
                    <div class="hisrobortchathtml robortchathtml">
                      <TextChat
                        ref="textRef"
                        :text="item.content"
                        :showCursor="false"
                      />

                    </div>

                    <!-- <div class="chatrobbottomicondiv">
                        <img src="../assets/chatrefreshicon.svg" alt="" class="chatbottomicon">
                        <img src="../assets/chatcopyicon.svg" alt="" class="chatbottomicon">
                        <img src="../assets/chatdeleteicon.svg" alt="" class="chatbottomicon">
                    </div> -->
                  </div>

                </div>
              </div>
            </div>
           
        </div>
      </div>
  </div>

  <!-- vip介绍的弹框 -->
  <el-dialog
    v-model="payvip_show"
    class="payvip"
    width="600px"
    align-center
    :show-close="false"
    style="height:800px"
  >
     <div style="display:flex;flex-direction: column;align-items: center;">
      <div style="display:flex;align-items: center;margin-top: 6px;">
        <img v-if="!isdark" src="../assets/vippaybuy.svg" alt="" style="width:32px;height:32px">
        <img v-if="isdark" src="../assets/darkvippaybuy.svg" alt="" style="width:32px;height:32px">
        <div class="paytop_font font_c333333">一次性购买</div>
      </div>
   
      <div style="display:flex;justify-content: center;align-items: flex-end;margin-top: 24px;">
        <img src="../assets/pay_69.svg" alt="" style="width:93px;height:68px">
        <img v-if="!isdark" src="../assets/pay_98.svg" alt="" style="width:56px;height:38px;margin-bottom: 5px;margin-left: 12px;">
        <img  v-if="isdark" src="../assets/darkpay_98.svg" alt="" style="width:56px;height:38px;margin-bottom: 5px;margin-left: 12px;">
      </div>
      <div style="display:flex;flex-direction: column;margin-left: 70px;">
        <div style="display:flex;align-items: center;margin-top:28px;    ">
          <img src="../assets/checkon.svg" alt="" class="paydivimg">
          <div>
            <div class="paycheckfonttop font_c333333">解锁所有功能</div>
            <div class="paycheckfontbottom font_c666666">搜索、AI字符、提示库等</div>
          </div>
        </div>
        <div style="display:flex;align-items: center;margin-top:12px">
          <img src="../assets/checkon.svg" alt="" class="paydivimg">
          <div>
            <div class="paycheckfonttop font_c333333">无限集成</div>
            <div class="paycheckfontbottom font_c666666">域检查、代码笔、代码突出显示等</div>
          </div>
        </div>
        <div style="display:flex;align-items: center;margin-top:12px">
          <img src="../assets/checkon.svg" alt="" class="paydivimg">
          <div>
            <div class="paycheckfonttop font_c333333">MacOS 应用程序</div>
            <div class="paycheckfontbottom font_c666666">最大便利性（MacOS 11.3+）</div>
          </div>
        </div>
        <div style="display:flex;align-items: center;margin-top:12px">
          <img src="../assets/checkon.svg" alt="" class="paydivimg">
          <div>
            <div class="paycheckfonttop font_c333333">自拖管选项</div>
            <div class="paycheckfontbottom font_c666666">在您自己的网域中托管静态应有</div>
          </div>
        </div>
      </div>
      <div class="paycheckfontbottom font_c666666" style="margin-top:24px">
        注意：您仍然需要 OpenAI API 密钥才能使用该应用程序。我们不出售 API 密钥。
      </div>
      <a href="" style="margin-top:8px">我已经购买了许可证</a>
      <button class="midcarbtn"><img src="../assets/shopcar.svg" alt="" class="carbtnimg" >立即购买</button>
      <div class="buybottomdiv" style="margin-top:24px;">
        购买团队许可证（10个用户）-499元
      </div>

      <div class="buybottomdiv" >
        购买团队许可证（50个用户）-1999元
      </div>

      <div class="buybottomdiv">
        购买定制品牌（新）-49元
      </div>
      <div style="margin-top:30px;display: flex;">
        <div class="yinsi">隐私政策</div>
        <el-divider direction="vertical" />
        <div class="yinsi">服务条款</div>
      </div>
    </div>
  </el-dialog>


</template>
 

<script>
import AudioRecorder from './audioRecorder.vue';
import Speechvuerob from './speechvuerob.vue';
import Speechvue from './speechvue.vue';
// import marked  from 'marked' 
import emitter from "../utils/eventBus";
import api from '../api/api';
import typelist from '../sass-style/type.js';
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import TextChat from './TextChat.vue'
import Speech from 'speak-tts'
import axios from 'axios'
import Recorder from 'js-audio-recorder'
  // const lamejs = require('lamejs')
  
export default {
  emits: ['changeismenu'],
  name: 'HelloWorld',
  components: {
    AudioRecorder,
    Speechvue,
    TextChat,
    Speechvuerob,
  },

  props: {
    isdark:Boolean,
    ismenu:Boolean
  },
  data(){
    return {
      picdialogImageUrl: '',
      picdialogVisible: false,
      picfileList:[],
      chatrobtype:1,
      userinfo:{},
      gtptypelist:[],
      checkdata:{},
      eventSourceobj:"",

      voiceid:"",
      schema:"",
      filenameinput:"",
      fileurlinput:"",
      voiceuseinput:false,
      talkstatus:"running",
      userNowInputUrl:[],
      isvoicestop:false,
      playTime:0,
      openRecorder:false,
      gpttype:"GTP-3",
      robotavart:'',
      newchatindex:0,
      imageindex:1,
      chathistoryshow:false,
      promptWords:'',//提示词
      promptChlidList:[],//提示词二级列表
      promptWordsList:[],//提示词一级列表
      prompts_id:0,//机器人id
      prompts_title:'',//机器人名称
      type_task:'chat', //是聊天？还是绘图
      test_input:'',
      temp_image:'',
      percentage:0,
      recognizedText:'',//语音转文字
      speech: null, //文字转语音

      text:'一个单口喜剧演员，可以用他们的笑话和幽默让你发笑',
      recordertimer:'',
      
      payvip_show:false,//付费vip开关
      chat_loading:false,
      userNowInput:'',//用户当前输入框数据
      isphone:true,
      
      ischatcontent:false,  //是否有对话框数据
      recorder:'',

      chat_content:[],      //  显示聊天对话框
      image_time:'',          //获取图片的时间器

    }
  },  
  setup(){
    const {t} = useI18n()
    return {
      t
    }
  },
  mounted() {
    var that =this
    that.$nextTick(() => {
      that.scrollToBottom();
    });
  },
 
   created() {
    if (this._isMobile()) {
      this.isphone = true
    } else {
      this.isphone = false
    }
    // 初始化主题色

    this.promptWordsList = []
    var that = this
    typelist.forEach(element => {
        that.promptWordsList.push({text:element.text,value :element.value})
    });
    that.promptWords = that.promptWordsList[0].value
    that.changepromptWords()

    api.getchattype().then((res)=>{
        that.gtptypelist = res.data
        that.gpttype = that.gtptypelist[0].model
        that.checkdata = that.gtptypelist[0]
    });
    emitter.on("selecTypechat", (data) => {
      that.userinfo = data.userinfo
      if(!(data.userinfo&&data.userinfo.avatar != "")){
        that.userinfo.avatar = localStorage.getItem("avatar")
      }
      that.picfileList= []
      that.chathistoryshow = false
      that.robotavart = data.avatar
      that.prompts_id = data.id
      that.prompts_title = data.title
      that.chatrobtype = 1
      if(that.eventSourceobj != ""){
        that.eventSourceobj.close()
      }
      let senddata = {}
      switch(data.type){
        case 1 :
          that.type_task = "chat"
          that.resetdata()
          senddata.prompts_id = that.prompts_id
          api.historyMsg(senddata).then((res)=>{
            res.data.msg.forEach(element => {
              // that.chat_content = [{role:"assistant",content:element.MsgResp.msg}].concat(that.chat_content);
              // that.chat_content = [{role:'user',content:element.msg,filename:element.filename,fileurl:element.url}].concat(that.chat_content);
              that.chat_content.unshift({role:"assistant",content:element.MsgResp.msg})
              that.chat_content.unshift({role:'user',content:element.msg,filename:element.filename,fileurl:element.url})
            
            });
            that.$nextTick(() => {
              that.NoscrollToBottom();
            });
          });
          
          that.$forceUpdate()
        
          break
        case 2 :
          that.type_task = "image"
          that.resetdata()
          senddata.prompts_id = that.prompts_id
          console.log(data,7775)
          api.imageRequest(senddata).then((res)=>{
            res.data.msg.forEach(element => {
              that.chat_content.unshift({role:"assistant",image:element.MsgResp.msg,describe:"任务完成"})
              that.chat_content.unshift({role:'user',content:element.msg})
            });
            that.$nextTick(() => {
              that.NoscrollToBottom();
            });
          });
          that.$forceUpdate()
          break
        case 3:
          that.type_task = "voice"
          that.resetdata()
          localStorage.setItem("voicestatus",0)
          senddata.prompts_id = that.prompts_id
          api.historyVoiceMsg(senddata).then((res)=>{
            res.data.msg.forEach(element => {
              that.chat_content.unshift({role:"assistant",content:element.MsgResp.msg,voiceUrl:element.MsgResp.speech_url,id:element.MsgResp.id})
              that.chat_content.unshift({role:'user',content:element.msg,voiceUrl:element.speech_url,id:element.id})
            });
            that.$nextTick(() => {
              that.NoscrollToBottom();
            });
          });
          that.$forceUpdate()
          break
        case 5: 
        that.type_task = "chatnews"
          that.resetdata()
          senddata.prompts_id = that.prompts_id
          api.historyChatNewMsg(senddata).then((res)=>{
            res.data.msg.forEach(element => {
              that.chat_content.unshift({role:"assistant",content:element.MsgResp.msg})
              that.chat_content.unshift({role:'user',content:element.content})
            });
            that.$nextTick(() => {
              that.NoscrollToBottom();
            });
          });
          that.$forceUpdate()
          break
        case 6: 
          that.type_task = "creatimage"
          that.resetdata()
          senddata.prompts_id = that.prompts_id
          api.historyCreatImage(senddata).then((res)=>{
            res.data.msg.forEach(element => {
              that.chat_content.unshift({role:"assistant",content:element.MsgResp.content})
              that.chat_content.unshift({role:'user',content:element.msg})
            });
            that.$nextTick(() => {
              that.NoscrollToBottom();
            });
          });
          that.$forceUpdate()
          break
        case 7 :
          that.type_task = "chat"
          that.resetdata()
          that.chatrobtype = 2
          senddata.prompts_id = that.prompts_id
          api.googlehistoryMsg(senddata).then((res)=>{
            res.data.msg.forEach(element => {
              that.chat_content.unshift({role:"assistant",content:element.MsgResp.msg})
              that.chat_content.unshift({role:'user',content:element.msg,filename:element.filename})
            });
            that.$nextTick(() => {
              that.NoscrollToBottom();
            });
          });
          that.$forceUpdate()
          break
        case 8 :
          that.type_task = "chat"
          that.resetdata()
          that.chatrobtype = 3
          senddata.prompts_id = that.prompts_id
          api.binghistoryMsg(senddata).then((res)=>{
            res.data.msg.forEach(element => {
              that.chat_content.unshift({role:"assistant",content:element.MsgResp.msg})
              that.chat_content.unshift({role:'user',content:element.msg,filename:element.filename})
            });
            that.$nextTick(() => {
              that.NoscrollToBottom();
            });
          });
          that.$forceUpdate()
          break
      }
      
    })

  },
  watch:{
    chat_content: {
        deep: true,
        immediate: true,
        handler() {
          if(this.chat_content&&this.chat_content.length != 0){
            this.ischatcontent = true
          }else{
            this.ischatcontent = false
          }
        }
      },
       
  },  
  updated(){
  },
  methods:{
    getBase64(file) {
        return new Promise(function(resolve, reject) {
            let reader = new FileReader();
            let imgResult = "";
            reader.readAsDataURL(file);
            reader.onload = function() {
                imgResult = reader.result;
            };
            reader.onerror = function(error) {
                reject(error);
            };
            reader.onloadend = function() {
                resolve(imgResult);
            };
        });
    },
    pichandleAvatarSuccess(resnew, file) {
      let that = this
      this.getBase64(file.raw).then(res => {
        that.picfileList.push({name:resnew.data.name,url:resnew.data.url,uid:file.uid,base:res})
      });
    },
    pichandleRemove(file, fileList) {
      for(let i = 0; i < this.picfileList.length;i++){
        console.log(this.picfileList[i])
        if(this.picfileList[i].uid == file.uid){
          this.picfileList.splice(i,1)
          console.log(i)
        }
      }
    },
    handlehuituPictureCardPreview(file) {
      this.picdialogImageUrl = file.url;
      this.picdialogVisible = true;
    },
    isImage(filename) {
      var pattern = /\.(jpg|jpeg|png|gif|bmp)$/i;
      return pattern.test(filename);
    },
    beforeAvatarUpload(file) {
        const isJPG = this.isImage(file.name);
        if (!isJPG) {
          this.$message.error('必须为图片格式');
        }
        return isJPG;
    },
    beforefileUpload(file) {
        const isPDF = file.type === 'application/pdf';
        const isLtSize = file.size / 1024 / 1024 < 10;
        console.log(file)
        if (!isPDF) {
          this.$message.error('上传文件只能是 PDF 格式!');
        }
        if (!isLtSize) {
          this.$message.error('上传文件小于10M!');
        }
        return isPDF||isLtSize;
    },
    changegptype(item){
      this.gtptypelist.forEach(element => {
        if(element.model == item){
          this.checkdata = element
        }
      });
      console.log(this.checkdata)
    },
    urldelete(index){
      this.userNowInputUrl.splice(index,1)
    },
    inputfilesuccess(res){
      if(res.code == 0){
        this.schema=res.data.schema
        this.filenameinput=res.data.filename
        this.fileurlinput=res.data.fileurl
        
      }else{
        ElMessage({
          showClose: true,
          message: '上传文件失败',
          type: 'error',
        })
      }
    },
    filesuccessurl(res){
      this.userNowInputUrl.push(res.data.url)
    },
    startRecorder () {
      var that = this
      if(this.chat_loading){
        ElMessage({
          showClose: true,
          message: '说话太快啦~',
          type: 'error',
        })
        return
      }
      this.talkstatus = "running"
      this.recorder = new Recorder({
        sampleBits: 16,                 // 采样位数，支持 8 或 16，默认是16
        sampleRate: 48000,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1,                 // 声道，支持 1 或 2， 默认是1
        // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
      })
      Recorder.getPermission().then(() => { 
        this.recorder.start().then(() => { 
          that.openRecorder = true 
          that.isvoicestop = false
        })
      }, (error) => {
        ElMessage({
          showClose: true,
          message: '获取权限失败',
          type: 'error',
        })
        this.$Message.success('获取权限失败')
        console.log(error )
      });
    },

    handleResumePlay() {
      console.log('恢复播放')
      this.recorder.resume() // 恢复播放
      var that = this
      that.isvoicestop = false
      that.talkstatus = "running"
      // 播放时长
      this.recordertimer = setInterval(() => {
        try {
          that.playTime = this.recorder.duration
        } catch (error) {
          this.recordertimer = null
        }
      }, 100)
    },
     // 停止录音播放
     stopPlayRecorder () {
        console.log('停止播放')
        this.recorder.pause() // 停止播放
        this.isvoicestop = true
        this.$forceUpdate()
        // 播放时长
        this.talkstatus = "paused"
        console.log(this.recorder,988)
        this.playTime = this.recorder.duration
        this.recordertimer = null
      },
    voiceDestroy() {
      if(!this.openRecorder){
        ElMessage({
              showClose: true,
              message: '请先录音',
              type: 'error',
            })
          return 
      }
      console.log('销毁实例')
      this.recorder.destroy() // 毁实例
      this.recordertimer = null
      this.talkstatus = "running"
      this.openRecorder = false 
      this.isvoicestop = false
    },

    selectimagesize(index){
      this.imageindex = index
      console.log(123,this.imageindex)
      this.$forceUpdate()
    },
    gotologin(){
      this.$router.push({name:'Vlogin'})
    },
    selectPrompt(item){
      item.select = true
      this.userNowInput =  this.userNowInput + " " + item.value + " " 
    },
    cancelPrompt(item){
      item.select = false
      this.userNowInput = this.userNowInput.replace(" " + item.value + " ", '');
    },
    resetdata(){
      this.userNowInput == ''
      this.chat_loading  = false
      this.chat_content = []
    },
  
    addnewchat(){
      if(this.chat_content[this.chat_content.length-1].role == 'new'){
        return
      }
      this.chat_content.push({role:'new',content:""})
      this.newchatindex = this.chat_content.length-1
      this.$nextTick(() => {
          this.scrollToBottom();
        });
      // this.chat_content = []
    },

    //语音转文字
    startRecognition() {
      const recognition = new (window.SpeechRecognition || 
        window.webkitSpeechRecognition)();
      recognition.lang = 'zh-CN'; // 设置语言为中文

      recognition.onresult = (event) => {
        const text = event.results[0][0].transcript;
        this.recognizedText = text;
       
        console.log(event,998)
      };
      
      recognition.start();
    },
    endRecognition(){
      const recognition = new (window.SpeechRecognition || 
        window.webkitSpeechRecognition)();
        recognition.stop();
    },

    //文字转语音
    speakTtsSpeech() {
      this.speech.speak({ text: "公众号:霸道的程序猿" }).then(() => {
        console.log("读取成功");
      });
    },
   
    changepromptWords(){
      console.log(this.promptWords)
      this.promptChlidList  = []
      typelist.forEach(element => {
        if(this.promptWords == element.value){
          this.promptChlidList = element.chlidren
        }
      });
      this.promptChlidList.forEach(elementtemp => {
        elementtemp.chlidren.forEach(obj => {
          obj.select = false
        });
            elementtemp.select = false
      });
      console.log(this.promptChlidList)
    },
    showhistory(){
      this.chathistoryshow = !this.chathistoryshow
      if(this.chathistoryshow){
        this.scrollToBottomNew()
      }
    },

    scrollToBottomNew() {
      var that = this
        this.$nextTick(() =>{
          var itemBox = that.$refs.newitemBox;
            itemBox.scrollTo({ top: itemBox.scrollHeight, behavior: 'smooth' });
        })
    },
    scrollToBottom(){
      var itemBox = this.$refs.itemBox;
      if(itemBox){
        itemBox.scrollTo({ top: itemBox.scrollHeight, behavior: 'smooth' });
      }
    },
    NoscrollToBottom(){
      var itemBox = this.$refs.itemBox;
      if(itemBox){
        itemBox.scrollTo({ top: itemBox.scrollHeight });
      }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    changemenu(){
      this.$emit('changeismenu',true)
    },
    GooglehandleSubmit() {
      var that = this
      console.log(23123123)
      if(this.userNowInput == ''||this.chat_loading){
        return
      }
      this.chat_loading = true
      console.log(this.schema,3928392839)
     
      this.chat_content.push({role:'user',content:this.userNowInput})
      this.$nextTick(() => {
        this.scrollToBottom();
      });


      let putchat_content = []
    
      if(this.newchatindex == 0 ||this.newchatindex <this.chat_content.length-5){
          if(this.chat_content.length-5 <= 0){
            putchat_content = this.chat_content
          }else{
            putchat_content = this.chat_content.slice(this.chat_content.length-5,this.chat_content.length)
          }
        }else{
          if(this.chat_content.length-5 <= 0){
            putchat_content = this.chat_content
          }else{
            putchat_content = this.chat_content.slice(this.newchatindex+1,this.chat_content.length)
          }
        }
    
      let msg = {type:that.checkdata.type,"rate":150,prompts_id:this.prompts_id,completion:{model: "gemini-pro",messages:putchat_content}}
      
      that.userNowInput = ''
      that.text = ""
      that.showCursor = true
      api.googleRequest(msg).then((res)=>{
        if(res.code == 0){
          that.schema = ""
 
          if(localStorage.getItem('vb-token')&&localStorage.getItem('vb-token')!=''){
            that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/googlecompletionStream?Authorize='+localStorage.getItem('vb-token'));
          }else{
            that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/googlecompletionStream');
          }
          that.eventSourceobj.addEventListener('message', (event) => {
            if(event.data == "[done]"){
              that.eventSourceobj.close()
              that.showCursor = false
              that.chat_content.push({role:"assistant",content:that.text})
              that.$nextTick(() => {
                emitter.emit("getuserinfo")
                that.scrollToBottom();
              });
              that.chat_loading = false
              return
            }
            if(event.data.indexOf("{")==-1){
              that.text= that.text + event.data
            }else{
              let eventdata = JSON.parse(event.data)
              if(eventdata[0].delta&&eventdata[0].delta.content){
                that.text= that.text + eventdata[0].delta.content;
              }
            }
            
            that.$nextTick(() => {
                that.scrollToBottom();
              });
            that.$forceUpdate()
          });
        }else{
          ElMessage({
            showClose: true,
            message: res.msg,
            type: 'error',
          })
          that.chat_loading = false
        }
      });
    },
    BinghandleSubmit() {
      var that = this
      console.log(23123123)
      if(this.userNowInput == ''||this.chat_loading){
        return
      }
      this.chat_loading = true
      console.log(this.schema,3928392839)
     
      this.chat_content.push({role:'user',content:this.userNowInput})
      this.$nextTick(() => {
        this.scrollToBottom();
      });


      let putchat_content = []
    
      if(this.newchatindex == 0 ||this.newchatindex <this.chat_content.length-5){
          if(this.chat_content.length-5 <= 0){
            putchat_content = this.chat_content
          }else{
            putchat_content = this.chat_content.slice(this.chat_content.length-5,this.chat_content.length)
          }
        }else{
          if(this.chat_content.length-5 <= 0){
            putchat_content = this.chat_content
          }else{
            putchat_content = this.chat_content.slice(this.newchatindex+1,this.chat_content.length)
          }
        }
    
      let msg = {type:that.checkdata.type,"rate":150,prompts_id:this.prompts_id,completion:{model: "bing",messages:putchat_content}}
      
      that.userNowInput = ''
      that.text = ""
      that.showCursor = true
      api.bingRequest(msg).then((res)=>{
        if(res.code == 0){
          that.schema = ""
       
          if(localStorage.getItem('vb-token')&&localStorage.getItem('vb-token')!=''){
            that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/bingcompletionStream?Authorize='+localStorage.getItem('vb-token'));
          }else{
            that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/bingcompletionStream');
          }
          that.eventSourceobj.addEventListener('message', (event) => {
            if(event.data == "[done]"){
              that.eventSourceobj.close()
              that.showCursor = false
              that.chat_content.push({role:"assistant",content:that.text})
              that.$nextTick(() => {
                emitter.emit("getuserinfo")
                that.scrollToBottom();
              });
              that.chat_loading = false
              return
            }
            
            if(event.data.indexOf("{")==-1){
              that.text= that.text + event.data
            }else{
              let eventdata = JSON.parse(event.data)
              if(eventdata[0].delta&&eventdata[0].delta.content){
                that.text= that.text + eventdata[0].delta.content;
              }
            }
            that.$nextTick(() => {
                that.scrollToBottom();
              });
            that.$forceUpdate()
          });
        }else{
          ElMessage({
            showClose: true,
            message: res.msg,
            type: 'error',
          })
          that.chat_loading = false
        }
      });
    },
    handleSubmit() {
      var that = this
      if(this.userNowInput == ''||this.chat_loading){
        return
      }
      this.chat_loading = true
      console.log(this.schema,3928392839)
      if(this.schema != ""){
        let inputdata = {}
        inputdata.schema = this.schema
        inputdata.input = this.userNowInput
        that.showCursor = true
        api.getEmbeddingInput(inputdata).then((reback)=>{
          if(reback.code != 0){
            ElMessage({
                showClose: true,
                message: reback.msg,
                type: 'error',
              })
              that.chat_loading = false
              return
          }
      
          let putchat_content = []
          if(this.newchatindex == 0 ||this.newchatindex <this.chat_content.length-2){
            if(this.chat_content.length-4 <= 0){
              putchat_content = that.chat_content
            }else{
              putchat_content.push(this.chat_content[this.chat_content.length-1])
            }
          }else{
            if(this.chat_content.length-4 <= 0){
              putchat_content = this.chat_content
            }else{
              putchat_content.push(this.chat_content[this.chat_content.length-3])
              putchat_content.push(this.chat_content[this.chat_content.length-2])
              putchat_content.push(this.chat_content[this.chat_content.length-1])
            }
          
          }
          let prompt_tishi =""
          reback.data.forEach(element => {
            prompt_tishi = prompt_tishi + element
          });
          putchat_content.push({role:'user',content:prompt_tishi})
          putchat_content.push({role:'user',content:this.userNowInput,filename:this.filenameinput,fileurl:this.fileurlinput})
          this.chat_content.push({role:'user',content:this.userNowInput,filename:this.filenameinput,fileurl:this.fileurlinput})
          this.$nextTick(() => {
            this.scrollToBottom();
          });
      
          let msg = {type:that.checkdata.type,"rate":150,prompts_id:this.prompts_id,completion:{model: that.checkdata.model,messages:putchat_content}}
          
          that.userNowInput = ''
          that.text = ""
     
          api.gpt3Request(msg).then((res)=>{
            if(res.code == 0){
              that.schema = ""
              that.userNowInput = ""
        
              if(localStorage.getItem('vb-token')&&localStorage.getItem('vb-token')!=''){
                that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/completionStream?Authorize='+localStorage.getItem('vb-token'));
              }else{
                that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/completionStream');
              }
              that.eventSourceobj.addEventListener('message', (event) => {
                if(event.data == "[done]"){
                  that.eventSourceobj.close()
                  that.showCursor = false
                  that.chat_content.push({role:"assistant",content:that.text})
                  that.$nextTick(() => {
                    emitter.emit("getuserinfo")
                    that.scrollToBottom();
                  });
                  that.chat_loading = false
                  return
                }
                
                if(event.data.indexOf("{")==-1){
                  that.text= that.text + event.data
                }else{
                  let eventdata = JSON.parse(event.data)
                  if(eventdata[0].delta&&eventdata[0].delta.content){
                    that.text= that.text + eventdata[0].delta.content;
                  }
                }
                that.$nextTick(() => {
                    that.scrollToBottom();
                  });
                that.$forceUpdate()
              });
            }else{
              ElMessage({
                showClose: true,
                message: res.msg,
                type: 'error',
              })

              that.chat_loading = false
            }
          });
        });
      }else{
        this.chat_content.push({role:'user',content:this.userNowInput})
        this.$nextTick(() => {
          this.scrollToBottom();
        });


        let putchat_content = []
      
        if(this.newchatindex == 0 ||this.newchatindex <this.chat_content.length-5){
            if(this.chat_content.length-5 <= 0){
              putchat_content = this.chat_content
            }else{
              putchat_content = this.chat_content.slice(this.chat_content.length-5,this.chat_content.length)
            }
          }else{
            if(this.chat_content.length-5 <= 0){
              putchat_content = this.chat_content
            }else{
              putchat_content = this.chat_content.slice(this.newchatindex+1,this.chat_content.length)
            }
          }
      
        let msg = {type:that.checkdata.type,"rate":150,prompts_id:this.prompts_id,completion:{model: that.checkdata.model,messages:putchat_content}}
        
        that.userNowInput = ''
        that.text = ""
        that.showCursor = true
        api.gpt3Request(msg).then((res)=>{
          if(res.code == 0){
            that.schema = ""
            
            if(localStorage.getItem('vb-token')&&localStorage.getItem('vb-token')!=''){
              that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/completionStream?Authorize='+localStorage.getItem('vb-token'));
            }else{
              that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/completionStream');
            }
            that.eventSourceobj.addEventListener('message', (event) => {
              if(event.data == "[done]"){
                that.eventSourceobj.close()
                that.showCursor = false
                that.chat_content.push({role:"assistant",content:that.text})
                that.$nextTick(() => {
                  emitter.emit("getuserinfo")
                  that.scrollToBottom();
                });
                that.chat_loading = false
                return
              }
              
              if(event.data.indexOf("{")==-1){
              that.text= that.text + event.data
            }else{
              let eventdata = JSON.parse(event.data)
              if(eventdata[0].delta&&eventdata[0].delta.content){
                that.text= that.text + eventdata[0].delta.content;
              }
            }
              that.$nextTick(() => {
                  that.scrollToBottom();
                });
              that.$forceUpdate()
            });
          }else{
            ElMessage({
              showClose: true,
              message: res.msg,
              type: 'error',
            })
            that.chat_loading = false
          }
        });
      }


      
    },
    handleSubmitCreateImg() {
      var that = this
      if(this.userNowInput == ''||this.chat_loading){
        return
      }
      this.chat_loading = true
      this.chat_content.push({role:'user',content:this.userNowInput,size:"1024x1024",n:1})
      this.$nextTick(() => {
        this.scrollToBottom();
      });
      let putchat_content = [{role:'user',content:this.userNowInput,size:"1024x1024",n:1}]
      let msg = {type:'gpt-4-vision-preview',prompts_id:this.prompts_id,completion:{model: "gpt-4",messages:putchat_content}}
      that.userNowInput = ''
      that.text = ""
      that.showCursor = true
      api.gpt3CImageRequest(msg).then((res)=>{
        if(res.code == 0){
          api.CreatImage().then((res)=>{
            if(res.code == 0){
              that.showCursor = false
              that.chat_content.push({role:"assistant",content:res.data.data.data})
              that.$nextTick(() => {
                that.scrollToBottom();
                emitter.emit("getuserinfo")
              });
              that.chat_loading = false
            }else{
              that.showCursor = false
              ElMessage({
                showClose: true,
                message: res.msg,
                type: 'error',
              })
              that.chat_loading = false
            }
          });
        }else{
          ElMessage({
            showClose: true,
            message: res.msg,
            type: 'error',
          })
          that.chat_loading = false
        }
      });
    },
    handleChatNewsSubmit() {
      var that = this
      if(this.userNowInput == ''||this.chat_loading||this.userNowInputUrl.length == 0){
        return
      }
      this.chat_loading = true
      let conetent = []
      this.userNowInputUrl.forEach(element => {
        let imgdata = {}
        imgdata.url = element
        conetent.push({type:'image_url',image_url:imgdata})
      });
      conetent.push({type:'text',text:this.userNowInput})
      this.chat_content.push({role:'user',content:conetent})

      this.$nextTick(() => {
        this.scrollToBottom();
      });
      let putchat_content = [{role:'user',content:conetent}]
      let msg = {type:'gpt4v',"rate":150,prompts_id:this.prompts_id,completion:{model: "gpt-4-vision-preview",messages:putchat_content}}
      
      that.userNowInput = ''
      that.userNowInputUrl = []
      that.text = ""
      that.showCursor = true
      api.gpt3ChatNewsRequest(msg).then((res)=>{
        if(res.code == 0){
       
          if(localStorage.getItem('vb-token')&&localStorage.getItem('vb-token')!=''){
             that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/aImgCompletionStream?Authorize='+localStorage.getItem('vb-token'));
          }else{
            that.eventSourceobj = new EventSource('https://chat.onechatai.cn/chat/v1/chat/aImgCompletionStream');
          }
          that.eventSourceobj.addEventListener('message', (event) => {
            if(event.data == "[done]"){
              that.eventSourceobj.close()
              that.showCursor = false
              that.chat_content.push({role:"assistant",content:that.text})
              that.$nextTick(() => {
                that.scrollToBottom();
                emitter.emit("getuserinfo")
              });
              that.chat_loading = false
              return
            }
            
            if(event.data.indexOf("{")==-1){
              that.text= that.text + event.data
            }else{
              let eventdata = JSON.parse(event.data)
              if(eventdata[0].delta&&eventdata[0].delta.content){
                that.text= that.text + eventdata[0].delta.content;
              }
            }
            // that.scrollDown()
            that.$nextTick(() => {
                that.scrollToBottom();
              });
            that.$forceUpdate()
          });
        }else{
          ElMessage({
            showClose: true,
            message: res.msg,
            type: 'error',
          })
          that.chat_loading = false
        }
      });
    },
    imagehandleSubmit(){
      var that = this
      if(this.userNowInput == ''||this.chat_loading){
        return
      }
      this.chat_loading = true
   
      if(this.imageindex != 0){
        switch(this.imageindex){
          case 1: 
          this.userNowInput = " 1:1 " +   this.userNowInput;
          break
          case 2: 
          this.userNowInput = " 1:2 " +   this.userNowInput;
          break
          case 3: 
          this.userNowInput = " 3:4 " +   this.userNowInput;
          break
          case 4: 
          this.userNowInput = " 4:3 " +   this.userNowInput;
          break
          case 5: 
          this.userNowInput = " 9:16 " +   this.userNowInput;
          break
        }
      }
      this.chat_content.push({role:'user',content:this.userNowInput})
      that.$nextTick(() => {
          that.scrollToBottom();
      });
      let middata = {}
      middata.prompt = this.userNowInput
      if(this.picfileList.length >0){
        middata.base64Array = []
        for(let x =0 ;x<this.picfileList.length;x++){
          middata.base64Array.push(this.picfileList[x].base)
        }
      }
      axios.post('/imagechat/mj/submit/imagine',middata,{headers: { "mj-api-secret": "sk-Sx1OWNRjCYGnOWem01460d2537844815807e12219e3dDa5b" }}).then((resdata)=>{
        that.chat_content.push({role:"assistant",describe:"",status:true})
        that.$nextTick(() => {
            that.scrollToBottom();
        });
        that.picfileList = []
        var id = resdata.data.result
        that.image_time = setInterval(()=>{
          axios.get('/imagechat/mj/task/'+id+'/fetch',{headers: { "mj-api-secret": "sk-Sx1OWNRjCYGnOWem01460d2537844815807e12219e3dDa5b" }}).then((res)=>{
            that.percentage= res.data.progress.split("%")[0] *1
            that.temp_image = res.data.imageUrl
            that.$forceUpdate()
            if(res.data.status == "SUCCESS"){
              that.chat_content[that.chat_content.length-1].describe = "任务成功"
              that.chat_content[that.chat_content.length-1].image = res.data.imageUrl
              // that.chat_content.push({role:"assistant",describe:"任务提交成功"})
              console.log('over')
              emitter.emit("getuserinfo")
              that.chat_loading = false
              that.percentage= 0
              window.clearInterval(that.image_time)
             
              let putchat_content  = []
              putchat_content.push({role:'user',content:that.chat_content[that.chat_content.length-2].content})
              putchat_content.push({role:'assistant',content:that.chat_content[that.chat_content.length-1].image})
              let msg = {type:'gpt-4-vision-preview',"rate":150,prompts_id:this.prompts_id,completion:{model: "gpt-4",messages:putchat_content}}
              that.$nextTick(() => {
                that.scrollToBottom();
            });
              api.streamImage(msg).then(()=>{})

            }else if(res.data.status == "FAILURE"){
              that.chat_loading = false
              that.chat_content[that.chat_content.length-1].image = res.data.imageUrl
              that.chat_content[that.chat_content.length-1].status = false
              that.chat_content[that.chat_content.length-1].describe = res.data.failReason!=''?res.data.failReason:res.data.description
              window.clearInterval(that.image_time)
            }
            console.log(res)
          });
        },1000)
      });
      that.userNowInput = ''
      that.promptWords = that.promptWordsList[0].value
      this.promptChlidList= []

      // that.chat_loading = false
    },
    sendvoice(){
      var that = this
      if(this.voiceuseinput){
        if(this.userNowInput == ''||this.chat_loading){
          return
        }
          that.isvoicestop = false
          that.chat_content.push({role:'user',content:that.userNowInput ,voiceUrl:""})
          that.$nextTick(() => {
              that.scrollToBottom();
          });
          that.chat_loading = true
         
          let putchat_content = []
          that.userNowInput = ""
          if(this.newchatindex == 0 ||this.newchatindex <this.chat_content.length-5){
              if(this.chat_content.length-5 <= 0){
                putchat_content = this.chat_content
              }else{
                putchat_content = this.chat_content.slice(this.chat_content.length-5,this.chat_content.length)
              }
            }else{
            if(this.chat_content.length-5 <= 0){
              putchat_content = this.chat_content
            }else{
              putchat_content = this.chat_content.slice(this.newchatindex+1,this.chat_content.length)
            }
          }
          let msg = {type:'gpt-4-vision-preview',"rate":150,prompts_id:this.prompts_id,completion:{model: "gpt-4",messages:putchat_content}}
          api.gpt3VoiceRequest(msg).then((res)=>{
            if(res.code == 0){
              api.completionStream().then((res)=>{
                console.log(res)

                that.chat_content.push({role:"assistant",content:res.data.content,voiceUrl:res.data.url})
                that.$nextTick(() => {
                  that.scrollToBottom();
                });
          
                emitter.emit("getuserinfo")
                that.$forceUpdate()
                that.chat_loading = false
              })
            }else{
              ElMessage({
                showClose: true,
                message: res.msg,
                type: 'error',
              })
              that.userNowInput = ""
              that.chat_loading = false
            }
          });
       
      }else{
        if(this.recorder == null || this.recorder.duration == 0 ||this.chat_loading){
          ElMessage({
              showClose: true,
              message: '请先录音',
              type: 'error',
            })
          return 
        }
        that.chat_loading = true
        ElMessage({
            showClose: true,
            message: '上传中',
            type: 'success',
          })
        this.recorder.pause() // 暂停录音
        that.openRecorder = false 
      
        this.recordertimer = null
        console.log('上传录音')// 上传录音

        const formData = new FormData()
        const blob = this.recorder.getWAVBlob()// 获取wav格式音频数据
        // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
        const newbolb = new Blob([blob], { type: 'audio/wav' })
        const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
        formData.append('file', fileOfBlob)
   
        api.imagefileRequest(formData).then((res)=>{
          that.isvoicestop = false
          that.chat_content.push({role:'user',content:res.data.content,voiceUrl:res.data.url})
          that.$nextTick(() => {
              that.scrollToBottom();
          });
          if(res.code !== 0){
            ElMessage({
              showClose: true,
              message: res.msg,
              type: 'error',
            })
            return 
          }
        
          let putchat_content = []
      
          if(this.newchatindex == 0 ||this.newchatindex <this.chat_content.length-5){
              if(this.chat_content.length-5 <= 0){
                putchat_content = this.chat_content
              }else{
                putchat_content = this.chat_content.slice(this.chat_content.length-5,this.chat_content.length)
              }
            }else{
            if(this.chat_content.length-5 <= 0){
              putchat_content = this.chat_content
            }else{
              putchat_content = this.chat_content.slice(this.newchatindex+1,this.chat_content.length)
            }
          }
          let msg = {type:'gpt-4-vision-preview',"rate":150,prompts_id:this.prompts_id,completion:{model: "gpt-4",messages:putchat_content}}
          api.gpt3VoiceRequest(msg).then((res)=>{
            if(res.code == 0){
              api.completionStream().then((res)=>{
                console.log(res)

                that.chat_content.push({role:"assistant",content:res.data.content,voiceUrl:res.data.url})
                that.$nextTick(() => {
                  that.scrollToBottom();
                });
                that.$forceUpdate()
                emitter.emit("getuserinfo")
                that.chat_loading = false
              })
            }else{
              ElMessage({
                showClose: true,
                message: res.msg,
                type: 'error',
              })
              that.chat_loading = false
            }
          });
        })
      }
      
    },
  }
}
</script>

<style lang="scss">
@import "../sass-style/variable.scss";
.el-dialog{
  @include mid_ground_colorB8B8B8();
  border-radius: 8px !important;
}
.el-dialog__body{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
// .el-input__suffix{
//   display:none!important;
// }

.el-popper.is-pure{
  background: #1c1d22;
}


.el-select-dropdown__item.selected{
  color: #ffffff;
}
.el-select-dropdown__item.hover{
  background-color: #fbd026;
}

</style>
<style scoped lang="scss">
@import "../sass-style/midchat.css";
@import "../sass-style/variable.scss";

::v-deep .addphoto {
  .el-upload-list--picture-card .el-upload-list__item{
    border: 1px solid #3e4447;
  }
  .el-upload--picture-card{
    border: 1px solid #3e4447;
    background-color: #181a20;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail{
    background: #181a20;
  }
}


::v-deep .selectgpt .el-input__wrapper{
  background-color: #FBD026;
    border-radius: 20px;
    color:#fff;
    box-shadow: none;
}
::v-deep .selectgpt .el-input.is-focus .el-input__wrapper{
  box-shadow: none !important;
}

::v-deep .selectgpt .el-input__wrapper.is-focus{
  box-shadow: none !important;
}

.paytop_font{
  font-weight: 900;
  font-size: 25px;
  line-height: 35px;
  margin-left: 15px;
}
 .midtopdiv{
  width:100%;
    height:100%;
  border-radius: 16px;
  border: 0.5px solid hsl(0deg 0% 100% / 10%);
 }
 .minaddtop{
  width:calc(100% - 500px);
 }
.top-font{
  margin-top:36px;
  font-weight: 900;
  font-size: 48px;
  line-height: 67px;
}
.remark-font{
  margin-bottom:20px;
  margin-top:8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px
}
.checkdiv{
  display: flex;
  justify-content: flex-start;
  margin:7px 100px; 
  align-items: center;
}
.paycheckfonttop{
  font-size: 16px;
  line-height: 22px;
}
.paycheckfontbottom{
  font-size: 14px;
  line-height: 20px;
}
.midgiftbtn {
   display: flex;
   margin-top:26px;
    align-items: center;
    border-radius: 8px !important;
    box-shadow: none !important;
    border-color: transparent !important;
    width: 160px ;
    height: 48px ;
    @include mid_btn_color();
    cursor: pointer;
}




.yinsi{
  @include bot_font_color();
  font-size: 12px;
line-height: 17px;
}
.chatkey_font{
  font-size: 16px;
line-height: 28px;
text-align: justify;
}
.chatkeybot_font{
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}
.airolediv{
  width:46%;
  border-radius: 8px;
  margin: 10px 2%;
  height:96px;
  
}
.imagesize{
  cursor: pointer;
  width:144px;
  height:88px;
  border-radius:12px;
  background: #181a20;
 
  display: flex;
  justify-content: center;
}
.gptfont{
  font-size: 14px;
  line-height: 20px;
  margin-top:8px
}
.chatkey_input{
  width:468px;
  height:44px
}
.gptbtn{
  display: flex;
   margin-top:26px;
    align-items: center;
    background: #1380FF;;
    border-radius: 22px !important;
    box-shadow: none !important;
    border-color: transparent !important;
    width: 160px ;
    height: 28px ;
    justify-content: center;
    color:#fff;
    cursor: pointer;
}
.font_c333333{
  letter-spacing:2px;
  @include mid_font_color333333();
}
.font_red{
  @include mid_font_red();
}
.font_cEDEDED{
  @include font_cEDEDED();
}
.font_c666666{
  @include bottom_font_color666666();
}
.ground_cB8B8B8{
  @include mid_ground_colorB8B8B8();
}
.ground_c666666{
  @include back_666666();
}

.ground_c666666{
  @include back_666666();
}

.topborder{
  @include top_border_color();
}
.gptdiv{
  @include mid_btn_color();
  width:45%;
  border-radius: 8px;
  height:56px;
  background-color: #E5E7EB;
  display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
}
::v-deep .inputask .el-input__wrapper{
  background-color: initial;
  border-radius: 8px;
  box-shadow: none;
 

}
::v-deep .inputask .el-input__inner{
  color:white
}
::v-deep .keyinput .el-input__wrapper{
  background-color: initial;
  box-shadow: none;
  border-radius: 8px;
}
 
::v-deep .ground_c666666 .el-textarea__inner{
  background-color: initial;
  border-radius: 8px;
}
.inputask {
  // @include input_color();
  background-color: #181A20 !important;
}
.keyinput{
  @include inputkey_color();
  border-radius: 8px;
}


::v-deep .radioselect .el-radio-button__inner{
  background-color: #181A20 ;
  border-color:#181A20 !important;
  border-radius:12px !important;
  box-shadow: none !important;
}
::v-deep  .el-radio-button__original-radio:checked+.el-radio-button__inner{
  background-color:#006FF9
}
::v-deep .el-radio-group{
  background-color: #181A20 !important;
  padding:4px;
  border-radius: 12px;
}
::v-deep .el-divider__text{
  color: #757575;
    background: #1f222a;
}
::v-deep .el-divider--horizontal{
  border-color: #757575;
}

 /* 平板之类的宽度 1024 以下设备 */
 @media only screen and (min-width: 321px) and (max-width: 1024px) {
    .midtopmenu{
        width:100%;
        background-color: #1C1D22;
        padding:0;
    }
    .openmidtopmenu{
        width:calc(100% - 300px);
        background-color: #1C1D22;
        padding:0;
        
   
    }
    .midtopdiv{
      border-radius: 0;
    }
    .bottomthirdbtn{
      flex-direction: column;
    }
    .phonediv{
      height:79% !important; 
      overflow-y: auto !important; 
    }

    .phonechatdiv{
      padding: 0 3%;
      width: 94%;
      overflow:auto
    }
    .chatusericon{
      border-radius: 8px;
      height: 36px;
      width:36px;
    }

    .userchathtml{
      position: relative;
      padding:20px 35px;
      margin-left: 10px;
      margin-right: 46px;
      max-width: 1000px;
    }
    
    .robortchathtml{
      padding:8px 10px;
      margin-left: 46px;
      margin-right: 10px;
    }
    .robortchatdiv{
      margin-top:30px;
    }
   
    .top-font{
      line-height:36px;
      margin-top:16px;
      font-size: 36px;
    }
    .remark-font{
      margin-top:20px;
      margin-bottom:20px;
      line-height:20px;
      font-size: 20px;
    }
    .midgroup{
      margin-top:15px
    }
  }

  .robortchathtml{
    letter-spacing:1px;
    border-width: 4px ;
    border-color: #f3f5f7;
    @include chat_color();
    @include chat_backcolor();
  }
  .userchathtml{
    letter-spacing:1px;
    border-width: 4px ;
    border-color: #f3f5f7;
    @include chat_color();
    @include chat_backcolor();
  }

.scrollbardiv {
	&::-webkit-scrollbar {
		width: 6px;
		height: 8px;
	}
	// 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
	&::-webkit-scrollbar-button {
		display: none;
	}
	// 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
	&::-webkit-scrollbar-thumb {
		background: rgba(144, 147, 153, 0.3);
		cursor: pointer;
		border-radius: 4px;
	}
	// 边角，即两个滚动条的交汇处
	&::-webkit-scrollbar-corner {
		display: none;
	}
	// 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
	&::-webkit-resizer {
		display: none;
	}
}
 
</style>
