import { createRouter, createWebHistory } from "vue-router";

const routerHistory = createWebHistory();
import Vpagecopy from "../view/Test.vue";
import Vpage from "../view/page.vue";
import Vlogin from "../view/login.vue";
import openask from "../view/openask.vue";
import tokenLogin from "../view/tokenLogin.vue";
// 定义路由
const routes = [
  {
    path: "/",
    name: "page",
    component: Vpage,
    meta: { title: "ChatNinja 你的AI忍者助手" },
  },
  {
    path: "/login",
    name: "Vlogin",
    component: Vlogin,
    meta: { title: "ChatNinja 你的AI忍者助手" },
  },
  {
    path: "/logintest",
    name: "Vpagecopy",
    component: Vpagecopy,
    meta: { title: "ChatNinja 你的AI忍者助手" },
  },
  {
    path: "/openask",
    name: "openask",
    component: openask,
    meta: { title: "ChatNinja 你的AI忍者助手" },
  },
  {
    path: "/chatninja/:token",
    name: "tokenLogin",
    component: tokenLogin,
    meta: { title: "ChatNinja 你的AI忍者助手" },
  },
];

// 创建路由器
const router = createRouter({
  mode: "hash",
  history: routerHistory,
  routes: routes,
});

export default router;
