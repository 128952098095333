<template>
  <!-- 左边菜单 -->
  <div style="display: flex;justify-content: center;align-items: center;height: 100vh;background-color: #1C1D22;;">
    <div style="width:960px;height:600px;display: flex;align-items: center;">
      <div style="width:440px;height:600px;position:relative;background-color: white;border-top-left-radius: 12px;border-bottom-left-radius: 12px;">
        <img  src="../assets/login/login_back.svg" alt=""  style="height:100%;position: absolute;">
        <img  src="../assets/login/logo.svg" alt=""  style="height:35px;position: absolute;top:40px;left:35px">
        <div style="padding:160px 76px 100px 64px;width:300px;height:340px;display: flex;flex-direction: column;justify-content: space-between;;">
            <div class="midshow" style="border-radius: 12px;background-color: white;width:300px;height:300px;z-index: 99;">
              <div v-if="qrcode==''" style="height:250px;width:250px;background-color: #E8E8E8;">

              </div>
              <img v-else :src="qrcode" alt="" style="height:250px;width:250px;">
            </div>
            <img  src="../assets/login/scanma.svg" alt=""  style="height:20px;z-index:99">
        </div>
      </div>
      <div class="borderdiv" style="width:520px;height:596px;">
        <div v-if="wxlogin" style="padding:72px 80px;width:360px;height:452px">
            <div style="font-size:32px;color:white">
              使用微信登录
            </div>
            <div class="midshow" style="width:100%;margin-top:64px;">
                <div class="midshow" style="width:180px;height:180px;background-color: white;">
                  <div  v-if="wxqrcode==''||showDefaultImg" style="width:160px;height:160px;background-color: #E8E8E8;">

                  </div>
                  <img v-else :src="wxqrcode" alt="" style="height:250px;width:250px;" @error="wxqrcode ='../assets/image/wxinfo/scanma.svg'" @load="onImageLoad">
                </div>

            </div>
            <el-divider style="color:#2C2F35;border-color:#2C2F35;padding: 0;margin-top: 60px;">
              <div style="background-color:#1C1D22;color:#2C2F35">
                或者
              </div>
            </el-divider>
            <div class="midshow" style="margin-top:28px">
              <div class="midshow" style="width:80px;height:54px;border-radius:12px;border:1px solid #35383F;cursor: pointer;" @click="wxlogininfo(2)">
                <img src="../assets/login/loginphone.svg" alt=" " style="width:24px;height:24px">
              </div>
            </div>
        </div>
        <div v-else style="padding:72px 80px;width:360px;height:452px">
            <div style="font-size:32px;color:white">
              使用手机号登录
            </div>
            <div  style="width:100%;margin-top:64px;">
              <el-input v-model="phone"  placeholder="请输入手机号" size="large"/>

              <div style="margin-top:26px;">
                <DragVerify @parentFn="sidersuccess"></DragVerify>
              </div>

              <div class="midshow" style="margin-top:26px;justify-content: space-between;"> 
                <el-input v-model="code"   placeholder="请输入验证码" size="large" style="width:230px;margin-right: 12px;"/>
                <el-button class="midshow buttonbtn verifycode" @click="getCaptcha" :disabled="codetext == '获取验证码' ? false : true" type="primary">
                  {{ codetext }}
                </el-button>
              </div>

              <div class="midshow buttonbtn loginbtn" @click="login()"  :style="code==''?{'background-color':'#1F222A','color':'#999999'}:{'background-color':'#006FF9','color':'#FFFFFF'}">
                登录
              </div>
             
            
            </div>
            <!-- <el-divider style="color:#2C2F35;border-color:#2C2F35;padding: 0;margin-top: 60px;">
              <div style="background-color:#1C1D22;color:#2C2F35">
                或者
              </div>
            </el-divider>
            <div class="midshow" style="margin-top:28px;" >
              <div class="midshow" style="width:80px;height:54px;border-radius:12px;border:1px solid #35383F;cursor: pointer;" @click="wxlogininfo(1)">
                <img class="buttonbtn" src="../assets/login/loginwx.svg" alt=" " style="width:24px;height:24px">
              </div>
            </div> -->
        </div>
      </div>
   
    </div>
  </div>
</template>


<script setup>
 import DragVerify from "../components/dragVerify.vue";
//传给子组件的参数
</script>

<script>
import { ElMessage } from 'element-plus'
import api from '../api/api';
import emitter from "../utils/eventBus";
export default {
  emits: ['changeismenu'],
  name: 'HelloWorld',
  props: {
    ismenu:Boolean
  },
  data(){
    return {
      fangdou:false,
      showDefaultImg:false,
      codeshow:false,
      code:"",
      phone:"",
      qrcode:"",
      wxqrcode:'',
      wxscene:'',
      scene:'',
      wxlogin:false,
      input:"",
      codetext:'获取验证码',
      wxtime:{}
    }
  },  
  setup() {
 
  },
   created() {
    api.qrlogin().then((res)=>{
        if(res.code == 0){
          this.qrcode = res.data.qrcode
          this.scene = res.data.scene
        } 
    });

    emitter.on("issuccess", () => {
      if(!this.fangdou){
        this.fangdou = true
        this.codeshow = true
        this.getCaptcha()
      }
    })
 
  },
  methods:{
    onImageLoad(event) {
      this.showDefaultImg = !event.target.complete; // 判断图片是否加载完成
      
      if (!this.showDefaultImg && event.target.naturalWidth === 0) {
        // 若图片加载后自然宽度为零（表示加载失败），则再次触发错误事件
        const errorEvent = new Event('error');
        event.target.dispatchEvent(errorEvent);
      }
    },
    /* 手机号码 */
    validatePhoneNumber(str) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      return reg.test(str)
    },
    sidersuccess(){
      console.log(12312)
    },
    wxlogininfo(index){
      var that = this
      if(index == 1){
        this.wxlogin = true
        api.qrlogin().then((res)=>{
            if(res.code == 0){
              this.wxqrcode = res.data.qrcode
              this.wxscene = res.data.scene
              that.wxtime = setInterval(() => {
                api.checkqrcode(this.wxscene).then((res)=>{
                  if(res.code == 0){
                    if(res.data.token!="noLogin"){
                      localStorage.setItem('vb-token',res.data.token)
                      this.$router.push({name:'page'})
                      clearInterval(that.wxtime);
                    }
                    
                  }else{
                    ElMessage({
                      message: '登录失败',
                      type: 'error'
                    })
                  }
                });
              }, 2000);

            } 
        });
 
      }else{
        this.wxlogin = false
        clearInterval(that.wxtime);
      }
    },
    login(){
      let data = {}
      data.phone = this.phone
      data.smsCode = this.code
      api.login(data).then((res)=>{
        if(res.code == 0){
          localStorage.setItem('vb-token',res.data.token)
          this.$router.push({name:'page'})
        }else{
          ElMessage({
            message: '登录失败',
            type: 'error'
          })
        }
      });
    },
    getCaptcha(){
      if(!this.validatePhoneNumber(this.phone)){
        emitter.emit("reset")
        ElMessage({
          message: '请输入正确手机号',
          type: 'error'
        })
        this.fangdou = false
        return
      }
      if(this.phone == ""){
        ElMessage({
            message: '请输入手机号',
            type: 'error'
          })
          this.fangdou = false
          return
      }
      if(!this.codeshow){
        this.fangdou = false
        ElMessage({
            message: '请先滑动验证',
            type: 'error'
          })
          return
      }
      let n = 60;
      this.codetext = n + "秒";
      const timer = setInterval(() => {
        if (n === 0) {
          clearInterval(timer);
          this.codetext = "获取验证码";
        } else {
          n--;
          this.codetext = n + "秒";
        }
      }, 1000);
      let data = {}
      data.phone = this.phone
      api.getcode(data).then((res)=>{
        this.fangdou = false
        if(res.code == 0){
          ElMessage({
              message: '发送成功',
              type: 'success'
          })
        }else{
          ElMessage({
            message: res.msg,
            type: 'error'
          })
        }
      });
      
    }
  }
}
</script>

<style scoped lang="scss">
@import "../sass-style/variable.scss";
 .borderdiv{
  border-top: 2px solid #69728b54;
  border-bottom: 2px solid #69728b54;
  border-right: 2px solid #69728b54;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
 }
 ::v-deep .el-divider__text{
  background-color: #1C1D22;
  padding-left: 10px;
  padding-right: 10px;
 }
 .midshow{
  display:flex;align-items: center;justify-content: center;
 }
 ::v-deep .el-input__wrapper{
  background-color: #1F222A;
  box-shadow: none;
  border-radius: 8px;

 }
 ::v-deep .el-input__inner{
  color:white
 }
 .buttonbtn{
  cursor: pointer;
 }
 .verifycode{
  background-color: #006FF9;
    border-color: #006FF9;
  // width:142px;
  height:40px;
  border-radius:6px;
  font-size: 16px;
  letter-spacing:0.5px;
 }
 .loginbtn{

  width:360px;
  height:44px;
  border-radius:36px;font-size: 16px;margin-top:30px;letter-spacing:1px;
 }
</style>
