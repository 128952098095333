const typelist =  [
  {
    "text": "质量",
    "value": "quality",
    "chlidren": [
      {
        "text": "标准",
        "value": "标准",
        "chlidren": [
          {
            "text": "超高清",
            "value": "UHD"
          },
          {
            "text": "解剖学正确",
            "value": "anatomically correct"
          },
          {
            "text": "准确",
            "value": "ccurate"
          },
          {
            "text": "质感皮肤",
            "value": "textured skin"
          },
          {
            "text": "非常详细",
            "value": "super detail"
          },
          {
            "text": "高细节",
            "value": "high details"
          },
          {
            "text": "屡获殊荣",
            "value": "award winning"
          },
          {
            "text": "最佳质量",
            "value": "best quality"
          },
          {
            "text": "高质量",
            "value": "high quality"
          }
        ]
      },
      {
        "text": "画质",
        "value": "画质",
        "chlidren": [
          {
            "text": "1080P",
            "value": "1080P"
          },
          {
            "text": "视网膜屏",
            "value": "retina"
          },
          {
            "text": "HD",
            "value": "HD"
          },
          {
            "text": "16k",
            "value": "16k"
          },
          {
            "text": "8k",
            "value": "8k"
          },
          {
            "text": "4K",
            "value": "4K"
          }
        ]
      }
    ]
  },
  {
    "text": "构图",
    "value": "composition",
    "chlidren": [
      {
        "text": "形式",
        "value": "形式",
        "chlidren": [
          {
            "text": "残像",
            "value": "afterimage"
          },
          {
            "text": "边框",
            "value": "border"
          },
          {
            "text": "画框",
            "value": "framed"
          },
          {
            "text": "一部分画到了背景框外面",
            "value": "outside border"
          },
          {
            "text": "褪色边框",
            "value": "fading border"
          },
          {
            "text": "背景或画框是圆角",
            "value": "rounded corners"
          },
          {
            "text": "相机取景框",
            "value": "viewfinder"
          },
          {
            "text": "图表",
            "value": "chart"
          },
          {
            "text": "人设图",
            "value": "character chart"
          },
          {
            "text": "设定图",
            "value": "reference sheet"
          },
          {
            "text": "图表",
            "value": "diagram"
          },
          {
            "text": "动作演示图",
            "value": "move chart"
          },
          {
            "text": "关系表",
            "value": "relationship graph"
          },
          {
            "text": "座次表",
            "value": "seating chart"
          },
          {
            "text": "属性栏/状态表",
            "value": "stats"
          },
          {
            "text": "拼贴画",
            "value": "collage"
          },
          {
            "text": "小图拼接",
            "value": "column lineup"
          },
          {
            "text": "胸围图",
            "value": "bust chart"
          },
          {
            "text": "遭到裁剪",
            "value": "cropped"
          },
          {
            "text": "假的滚动条",
            "value": "fake scrollbar"
          },
          {
            "text": "头部脱框",
            "value": "head out of frame"
          },
          {
            "text": "脱框",
            "value": "out of frame"
          },
          {
            "text": "脚部脱框",
            "value": "feet out of frame"
          },
          {
            "text": "等轴",
            "value": "isometric"
          },
          {
            "text": "宽银幕格式",
            "value": "letterboxed"
          },
          {
            "text": "柱状画布背景",
            "value": "pillarboxed"
          },
          {
            "text": "一排人",
            "value": "lineup"
          },
          {
            "text": "马赛克艺术",
            "value": "mosaic art"
          },
          {
            "text": "马赛克拼图",
            "value": "photomosaic"
          },
          {
            "text": "大量留白",
            "value": "negative space"
          },
          {
            "text": "附图",
            "value": "omake"
          },
          {
            "text": "部分水下拍摄",
            "value": "partially underwater shot"
          },
          {
            "text": "社交媒体整合",
            "value": "social media composition"
          },
          {
            "text": "左右对称",
            "value": "symmetry"
          },
          {
            "text": "两极对称",
            "value": "polar opposites"
          },
          {
            "text": "对称旋转",
            "value": "rotational symmetry"
          },
          {
            "text": "立绘样式",
            "value": "tachi-e"
          },
          {
            "text": "裁剪标记",
            "value": "trim marks"
          },
          {
            "text": "人物立绘缩放(剪影)图层",
            "value": "zoom layer"
          },
          {
            "text": "类似海报或杂志的插图效果",
            "value": "projected inset"
          }
        ]
      },
      {
        "text": "镜头",
        "value": "镜头",
        "chlidren": [
          {
            "text": "镜头光晕",
            "value": "lens flare"
          },
          {
            "text": "过曝",
            "value": "overexposure"
          },
          {
            "text": "背景散焦",
            "value": "bokeh"
          },
          {
            "text": "焦散",
            "value": "caustics"
          },
          {
            "text": "衍射十字星",
            "value": "diffraction spikes"
          },
          {
            "text": "正前缩距透视法",
            "value": "foreshortening"
          },
          {
            "text": "集中线",
            "value": "emphasis lines"
          },
          {
            "text": "卫星鸟瞰",
            "value": "satellite image"
          },
          {
            "text": "微距照片",
            "value": "macro photo"
          },
          {
            "text": "360 度视角",
            "value": "360 view"
          },
          {
            "text": "广角",
            "value": "Wide-Angle"
          },
          {
            "text": "超广角",
            "value": "Ultra-Wide Angle"
          },
          {
            "text": "人眼视角拍摄",
            "value": "Eye-Level Shot"
          },
          {
            "text": "光圈 F1.2",
            "value": "f/1.2"
          },
          {
            "text": "光圈 F1.8",
            "value": "f/1.8"
          },
          {
            "text": "光圈 F2.8",
            "value": "f/2.8"
          },
          {
            "text": "光圈 F4.0",
            "value": "f/4.0"
          },
          {
            "text": "光圈 F16",
            "value": "f/16"
          },
          {
            "text": "焦距 35mm",
            "value": "35mm"
          },
          {
            "text": "焦距 85mm",
            "value": "85mm"
          },
          {
            "text": "焦距 135mm",
            "value": "135mm"
          },
          {
            "text": "尼康",
            "value": "Nikon"
          },
          {
            "text": "佳能",
            "value": "Canon"
          },
          {
            "text": "富士",
            "value": "Fujifilm"
          },
          {
            "text": "哈数",
            "value": "Hasselblad"
          },
          {
            "text": "索尼镜头",
            "value": "Sony FE"
          },
          {
            "text": "索尼大师镜头",
            "value": "Sony FE GM"
          }
        ]
      },
      {
        "text": "视角",
        "value": "视角",
        "chlidren": [
          {
            "text": "第一人称视角",
            "value": "first-person view"
          },
          {
            "text": "主观视角",
            "value": "pov"
          },
          {
            "text": "三视图",
            "value": "three sided view"
          },
          {
            "text": "多视图",
            "value": "multiple views"
          },
          {
            "text": "插入画面",
            "value": "cut-in"
          },
          {
            "text": "前景模糊",
            "value": "blurry foreground"
          },
          {
            "text": "特写镜头",
            "value": "close-up"
          },
          {
            "text": "七分身镜头",
            "value": "cowboy shot"
          },
          {
            "text": "德式倾斜镜头",
            "value": "dutch angle"
          },
          {
            "text": "鱼眼镜头",
            "value": "fisheye"
          },
          {
            "text": "线影法(纹理)",
            "value": "hatching (texture)"
          },
          {
            "text": "远景透视画法",
            "value": "vanishing point"
          },
          {
            "text": "广角镜头",
            "value": "wide shot"
          },
          {
            "text": "俯视镜头",
            "value": "from above"
          },
          {
            "text": "背影",
            "value": "from behind"
          },
          {
            "text": "仰视镜头",
            "value": "from below"
          },
          {
            "text": "室外看向室内(的镜头)",
            "value": "from outside"
          },
          {
            "text": "角色的侧面",
            "value": "from side"
          },
          {
            "text": "大气距离感",
            "value": "atmospheric perspective"
          },
          {
            "text": "全景",
            "value": "panorama"
          },
          {
            "text": "透视画法",
            "value": "perspective"
          },
          {
            "text": "经过旋转的",
            "value": "rotated"
          },
          {
            "text": "横向显示的",
            "value": "sideways"
          },
          {
            "text": "倒挂的",
            "value": "upside-down"
          }
        ]
      }
    ]
  },
  {
    "text": "容貌",
    "value": "looks",
    "chlidren": [
      {
        "text": "眼睛",
        "value": "眼睛",
        "chlidren": [
          {
            "text": "眼下痣",
            "value": "mole under eye"
          },
          {
            "text": "爱心瞳",
            "value": "heart-shaped pupils"
          },
          {
            "text": "翻白眼",
            "value": "rolling eyes"
          },
          {
            "text": "疯狂的眼睛",
            "value": "crazy eyes"
          },
          {
            "text": "挑眉",
            "value": "raised eyebrows"
          },
          {
            "text": "下眉毛",
            "value": "furrowed brow"
          }
        ]
      },
      {
        "text": "头发",
        "value": "头发",
        "chlidren": [
          {
            "text": "条染",
            "value": "streaked hair"
          },
          {
            "text": "多彩头发",
            "value": "multicolored hair"
          },
          {
            "text": "内侧染色",
            "value": "colored inner hair"
          },
          {
            "text": "金发",
            "value": "blonde hair"
          },
          {
            "text": "银发",
            "value": "silver hair"
          },
          {
            "text": "灰发",
            "value": "grey hair"
          },
          {
            "text": "白发",
            "value": "white hair"
          },
          {
            "text": "茶发",
            "value": "brown hair"
          },
          {
            "text": "浅褐发",
            "value": "light brown hair"
          },
          {
            "text": "黑发",
            "value": "black hair"
          },
          {
            "text": "紫发",
            "value": "purple hair"
          },
          {
            "text": "红发",
            "value": "red hair"
          },
          {
            "text": "蓝发/水色发",
            "value": "blue hair"
          },
          {
            "text": "深蓝发",
            "value": "dark blue hair"
          },
          {
            "text": "浅蓝发",
            "value": "light blue hair"
          },
          {
            "text": "绿发",
            "value": "green hair"
          },
          {
            "text": "粉发",
            "value": "pink hair"
          },
          {
            "text": "渐变发色",
            "value": "gradient hair"
          },
          {
            "text": "彩虹发",
            "value": "rainbow hair"
          },
          {
            "text": "呆毛",
            "value": "ahoge"
          },
          {
            "text": "非对称发型",
            "value": "asymmetrical hair"
          },
          {
            "text": "刘海",
            "value": "bangs"
          },
          {
            "text": "齐刘海",
            "value": "blunt bangs"
          },
          {
            "text": "辫子",
            "value": "braid"
          },
          {
            "text": "编织马尾辫",
            "value": "braided ponytail"
          },
          {
            "text": "卷发",
            "value": "curly hair"
          },
          {
            "text": "窗帘/瀑布发型",
            "value": "curtained hair"
          },
          {
            "text": "双团子头",
            "value": "double bun"
          },
          {
            "text": "钻头卷/公主卷",
            "value": "drill hair"
          },
          {
            "text": "双钻头卷",
            "value": "twin drills"
          },
          {
            "text": "多钻头卷",
            "value": "quad drills"
          },
          {
            "text": "单侧钻头卷",
            "value": "side drill"
          },
          {
            "text": "法式辫",
            "value": "french braid"
          },
          {
            "text": "耳后发",
            "value": "hair behind ear"
          },
          {
            "text": "眼间刘海",
            "value": "hair between eyes"
          },
          {
            "text": "交错刘海",
            "value": "crossed bangs"
          },
          {
            "text": "团子头",
            "value": "hair bun"
          },
          {
            "text": "进气口发型",
            "value": "hair intakes"
          },
          {
            "text": "披肩发",
            "value": "hair over shoulder"
          },
          {
            "text": "姬发式",
            "value": "hime cut"
          },
          {
            "text": "长发",
            "value": "long hair"
          },
          {
            "text": "凌乱发型",
            "value": "messy hair"
          },
          {
            "text": "分开的刘海",
            "value": "parted bangs"
          },
          {
            "text": "马尾",
            "value": "ponytail"
          },
          {
            "text": "短发",
            "value": "short hair"
          },
          {
            "text": "短马尾",
            "value": "short ponytail"
          },
          {
            "text": "朝一个方向的刘海",
            "value": "side swept bangs"
          },
          {
            "text": "侧马尾",
            "value": "side ponytail"
          },
          {
            "text": "双辫子",
            "value": "twin braids"
          },
          {
            "text": "双马尾",
            "value": "twintails"
          },
          {
            "text": "很长的头发",
            "value": "very long hair"
          },
          {
            "text": "前马尾",
            "value": "front ponytail"
          },
          {
            "text": "短双马尾",
            "value": "short twintails"
          },
          {
            "text": "折叠马尾",
            "value": "folded ponytail"
          },
          {
            "text": "四马尾",
            "value": "quad tails"
          },
          {
            "text": "单辫",
            "value": "single braid"
          },
          {
            "text": "低双辫",
            "value": "low twin braids"
          },
          {
            "text": "侧辫",
            "value": "side braid"
          },
          {
            "text": "冠型织辫",
            "value": "crown braid"
          },
          {
            "text": "脏辫",
            "value": "dreadlocks"
          },
          {
            "text": "锥形发髻",
            "value": "cone hair bun"
          },
          {
            "text": "辫子髻",
            "value": "braided bun"
          },
          {
            "text": "圆环发髻",
            "value": "doughnut hair bun"
          },
          {
            "text": "心形发髻",
            "value": "heart hair bun"
          },
          {
            "text": "自然卷",
            "value": "wavy hair"
          },
          {
            "text": "不对称刘海",
            "value": "asymmetrical bangs"
          },
          {
            "text": "扫浏海",
            "value": "swept bangs"
          },
          {
            "text": "耳前发",
            "value": "sidelocks"
          },
          {
            "text": "单耳前发",
            "value": "single sidelock"
          },
          {
            "text": "头发后梳",
            "value": "hair pulled back"
          },
          {
            "text": "侧发后梳",
            "value": "half updo"
          },
          {
            "text": "一侧绑发",
            "value": "hair one side up"
          },
          {
            "text": "双侧绑发",
            "value": "hair two side up"
          },
          {
            "text": "散发",
            "value": "hair spread out"
          },
          {
            "text": "漂浮的头发",
            "value": "floating hair"
          },
          {
            "text": "直发",
            "value": "straight hair"
          },
          {
            "text": "头发很多的",
            "value": "big hair"
          },
          {
            "text": "水晶状的头发",
            "value": "crystal hair"
          },
          {
            "text": "富有表现力的头发",
            "value": "expressive hair"
          },
          {
            "text": "头发遮着双眼",
            "value": "hair over eyes"
          },
          {
            "text": "强调一缕一缕感的发型/发丝",
            "value": "hair strand"
          },
          {
            "text": "头发遮住了一只眼睛",
            "value": "hair over one eye"
          },
          {
            "text": "有光泽的头发",
            "value": "shiny hair"
          },
          {
            "text": "湿头发",
            "value": "wet hair"
          },
          {
            "text": "垂下的长鬈发",
            "value": "hair slicked back"
          },
          {
            "text": "披在两侧的两条辫子",
            "value": "high ponytail"
          },
          {
            "text": "侧马尾",
            "value": "long braid"
          },
          {
            "text": "直发",
            "value": "low-tied long hair"
          },
          {
            "text": "低扎马尾",
            "value": "low ponytail"
          },
          {
            "text": "低扎双尾",
            "value": "low twintails"
          },
          {
            "text": "中等长发",
            "value": "medium hair"
          },
          {
            "text": "垂下的长鬈发",
            "value": "ringlets"
          },
          {
            "text": "披在两侧的两条辫子",
            "value": "side braids"
          },
          {
            "text": "披在两侧的发髻",
            "value": "side bun"
          },
          {
            "text": "尾部散开的单马尾发型",
            "value": "split ponytail"
          },
          {
            "text": "小型双股辫",
            "value": "two side up"
          },
          {
            "text": "超长的头发",
            "value": "absurdly long hair"
          },
          {
            "text": "云絮状发型",
            "value": "cloud hair"
          },
          {
            "text": "外卷发型",
            "value": "flipped hair"
          },
          {
            "text": "触手头发",
            "value": "tentacle hair"
          },
          {
            "text": "很短的头发",
            "value": "very short hair"
          },
          {
            "text": "掀起的刘海",
            "value": "bangs pinned back"
          },
          {
            "text": "辫子刘海",
            "value": "braided bangs"
          },
          {
            "text": "斜刘海",
            "value": "diagonal bangs"
          },
          {
            "text": "单侧进气口发型",
            "value": "single hair intake"
          },
          {
            "text": "耳状头发",
            "value": "hair ears"
          },
          {
            "text": "秃头",
            "value": "bald"
          },
          {
            "text": "秃头女孩",
            "value": "bald girl"
          },
          {
            "text": "锅盖头",
            "value": "bowl cut"
          },
          {
            "text": "寸头",
            "value": "buzz cut"
          },
          {
            "text": "丁髷",
            "value": "chonmage"
          },
          {
            "text": "平头/板寸头",
            "value": "crew cut"
          },
          {
            "text": "平顶",
            "value": "flattop"
          },
          {
            "text": "河童头",
            "value": "okappa"
          },
          {
            "text": "精灵头",
            "value": "pixie cut"
          },
          {
            "text": "帽盔式发型",
            "value": "undercut"
          },
          {
            "text": "波波头",
            "value": "bob cut"
          },
          {
            "text": "玉米垄发型",
            "value": "cornrows"
          },
          {
            "text": "鲻鱼头",
            "value": "mullet"
          },
          {
            "text": "弓形头发",
            "value": "bow-shaped hair"
          },
          {
            "text": "前辫",
            "value": "front braid"
          },
          {
            "text": "多股(麻花)辫",
            "value": "multiple braids"
          },
          {
            "text": "三股辫",
            "value": "tri braids"
          },
          {
            "text": "四股辫",
            "value": "quad braids"
          },
          {
            "text": "三发髻",
            "value": "triple bun"
          },
          {
            "text": "发圈",
            "value": "hair rings"
          },
          {
            "text": "扎头发",
            "value": "tied hair"
          },
          {
            "text": "单发圈",
            "value": "single hair ring"
          },
          {
            "text": "只扎了一边的头发",
            "value": "one side up"
          },
          {
            "text": "低辫长发",
            "value": "low-braided long hair"
          },
          {
            "text": "角发",
            "value": "mizura"
          },
          {
            "text": "多扎头发",
            "value": "multi-tied hair"
          },
          {
            "text": "日本发",
            "value": "nihongami"
          },
          {
            "text": "丸子头",
            "value": "topknot"
          },
          {
            "text": "两股辫子大小不一",
            "value": "uneven twintails"
          },
          {
            "text": "有三股辫子",
            "value": "tri tails"
          },
          {
            "text": "有五股辫子",
            "value": "quin tails"
          },
          {
            "text": "鸟窝头/爆炸头",
            "value": "afro"
          },
          {
            "text": "超大鸟窝头",
            "value": "huge afro"
          },
          {
            "text": "蜂窝头",
            "value": "beehive hairdo"
          },
          {
            "text": "蓬帕杜发型",
            "value": "pompadour"
          },
          {
            "text": "蓬松感油头",
            "value": "quiff"
          },
          {
            "text": "在摆动的头发",
            "value": "hair flaps"
          },
          {
            "text": "带着尖角的发型",
            "value": "pointy hair"
          },
          {
            "text": "刺刺的头发",
            "value": "spiked hair"
          },
          {
            "text": "美人尖",
            "value": "widow's peak"
          },
          {
            "text": "心形呆毛",
            "value": "heart ahoge"
          },
          {
            "text": "大呆毛",
            "value": "huge ahoge"
          },
          {
            "text": "多根呆毛",
            "value": "antenna hair"
          },
          {
            "text": "遮盖头发稀少部分",
            "value": "comb over"
          },
          {
            "text": "莫霍克发型",
            "value": "mohawk"
          },
          {
            "text": "孤颈毛",
            "value": "lone nape hair"
          },
          {
            "text": "头发比基尼",
            "value": "hair bikini"
          },
          {
            "text": "头发围巾",
            "value": "hair scarf"
          }
        ]
      },
      {
        "text": "头饰",
        "value": "头饰",
        "chlidren": [
          {
            "text": "头顶光环",
            "value": "halo"
          },
          {
            "text": "东金帽子",
            "value": "tokin hat"
          },
          {
            "text": "迷你礼帽",
            "value": "mini top hat"
          },
          {
            "text": "贝雷帽",
            "value": "beret"
          },
          {
            "text": "兜帽",
            "value": "hood"
          },
          {
            "text": "护士帽",
            "value": "nurse cap"
          },
          {
            "text": "三重冕",
            "value": "tiara"
          },
          {
            "text": "皇冠",
            "value": "crown"
          },
          {
            "text": "发卡",
            "value": "hairpin"
          },
          {
            "text": "头箍",
            "value": "hairband"
          },
          {
            "text": "发夹",
            "value": "hairclip"
          },
          {
            "text": "发带",
            "value": "hair ribbon"
          },
          {
            "text": "发花",
            "value": "hair flower"
          },
          {
            "text": "头饰",
            "value": "hair ornament"
          },
          {
            "text": "蝴蝶结发饰",
            "value": "hair bow"
          },
          {
            "text": "女仆头饰",
            "value": "maid headdress"
          },
          {
            "text": "丝带",
            "value": "ribbon"
          },
          {
            "text": "太阳镜",
            "value": "sunglasses"
          },
          {
            "text": "眼罩",
            "value": "blindfold"
          },
          {
            "text": "单眼罩",
            "value": "eyepatch"
          },
          {
            "text": "面具/眼罩/口罩",
            "value": "mask"
          },
          {
            "text": "首饰",
            "value": "jewelry"
          },
          {
            "text": "铃铛",
            "value": "bell"
          },
          {
            "text": "面纹",
            "value": "facepaint"
          },
          {
            "text": "兽角",
            "value": "horns"
          },
          {
            "text": "鹿角",
            "value": "antlers"
          },
          {
            "text": "三叶草发饰",
            "value": "clover hair ornament"
          },
          {
            "text": "月牙发饰",
            "value": "crescent hair ornament"
          },
          {
            "text": "恶魔的角",
            "value": "demon horns"
          },
          {
            "text": "蓬莱玉枝",
            "value": "jeweled branch of hourai"
          },
          {
            "text": "鱼形发饰",
            "value": "fish hair ornament"
          },
          {
            "text": "额前有宝石",
            "value": "forehead jewel"
          },
          {
            "text": "额前有图案",
            "value": "forehead mark"
          },
          {
            "text": "护额",
            "value": "forehead protector"
          },
          {
            "text": "簪子",
            "value": "kanzashi"
          },
          {
            "text": "头绳",
            "value": "hair bobbles"
          },
          {
            "text": "头发上成对的像无线蓝牙的发饰",
            "value": "hairpods"
          },
          {
            "text": "头发上系着铃铛",
            "value": "hair bell"
          },
          {
            "text": "心形眼镜",
            "value": "heart-shaped eyewear"
          },
          {
            "text": "护目镜",
            "value": "goggles"
          },
          {
            "text": "无框眼镜",
            "value": "rimless eyewear"
          },
          {
            "text": "下半无框眼镜",
            "value": "over-rim eyewear"
          },
          {
            "text": "卡米纳墨镜",
            "value": "kamina shades"
          },
          {
            "text": "头上别着护目镜",
            "value": "goggles on head"
          },
          {
            "text": "帽子上别着护目镜",
            "value": "goggles on headwear"
          },
          {
            "text": "戴着头戴显示设备",
            "value": "head mounted display"
          },
          {
            "text": "贴有绷带的脸",
            "value": "bandage on"
          },
          {
            "text": "缠着绷带的单眼",
            "value": "bandage over one eye"
          },
          {
            "text": "眼睛上的疤痕",
            "value": "scar across eye"
          },
          {
            "text": "脸颊上的疤痕",
            "value": "scar on cheek"
          },
          {
            "text": "蒙住的眼",
            "value": "covered eyes"
          },
          {
            "text": "医用口罩",
            "value": "surgical mask"
          },
          {
            "text": "口罩",
            "value": "mouth mask"
          },
          {
            "text": "面纱",
            "value": "mouth veil"
          },
          {
            "text": "厚如玻璃瓶底的圆眼镜",
            "value": "coke-bottle glasses"
          },
          {
            "text": "天狗面具",
            "value": "tengu mask"
          },
          {
            "text": "狐狸面具",
            "value": "fox mask"
          },
          {
            "text": "掀到头上的面具",
            "value": "mask on head"
          },
          {
            "text": "拉着口罩",
            "value": "mask pull"
          },
          {
            "text": "摘下的面具",
            "value": "mask removed"
          },
          {
            "text": "防毒面具",
            "value": "gas mask"
          },
          {
            "text": "锚形项圈",
            "value": "anchor choker"
          },
          {
            "text": "珠子项链",
            "value": "bead necklace"
          },
          {
            "text": "耳机",
            "value": "headphones"
          },
          {
            "text": "从后脑戴上的耳机",
            "value": "behind-the-head headphones"
          },
          {
            "text": "脖子上挂着口哨",
            "value": "whistle around neck"
          },
          {
            "text": "兽耳头罩",
            "value": "animal hood"
          },
          {
            "text": "戴眼镜的",
            "value": "bespectacled"
          },
          {
            "text": "软呢帽",
            "value": "fedora"
          },
          {
            "text": "女巫帽",
            "value": "witch hat"
          },
          {
            "text": "法师帽",
            "value": "wizard hat"
          },
          {
            "text": "带翅膀的头盔",
            "value": "winged helmet"
          },
          {
            "text": "放下的兜帽",
            "value": "hood down"
          },
          {
            "text": "戴起来的兜帽",
            "value": "hood up"
          },
          {
            "text": "水手帽",
            "value": "sailor hat"
          },
          {
            "text": "圣诞帽",
            "value": "santa hat"
          },
          {
            "text": "类似警帽的帽子",
            "value": "peaked cap"
          },
          {
            "text": "护肘",
            "value": "elbow pads"
          },
          {
            "text": "龙角",
            "value": "dragon horns"
          },
          {
            "text": "眼镜别在头上",
            "value": "eyewear on head"
          },
          {
            "text": "嘴角有痣/美人痣",
            "value": "mole under mouth"
          },
          {
            "text": "x发饰",
            "value": "x hair ornament"
          },
          {
            "text": "黑色发带",
            "value": "black hairband"
          },
          {
            "text": "发箍",
            "value": "hair scrunchie"
          },
          {
            "text": "白色发带",
            "value": "white hairband"
          },
          {
            "text": "发带",
            "value": "hair tie"
          },
          {
            "text": "青蛙发饰",
            "value": "frog hair ornament"
          },
          {
            "text": "食物发饰",
            "value": "food-themed hair ornament"
          },
          {
            "text": "星星发饰",
            "value": "star hair ornament"
          },
          {
            "text": "心形发饰",
            "value": "heart hair ornament"
          },
          {
            "text": "红色发带",
            "value": "red hairband"
          },
          {
            "text": "蝴蝶发饰",
            "value": "butterfly hair ornament"
          },
          {
            "text": "蛇发饰",
            "value": "snake hair ornament"
          },
          {
            "text": "洛丽塔发带",
            "value": "lolita hairband"
          },
          {
            "text": "羽毛头饰",
            "value": "feather hair ornament"
          },
          {
            "text": "蓝色发带",
            "value": "blue hairband"
          },
          {
            "text": "锚发饰",
            "value": "anchor hair ornament"
          },
          {
            "text": "叶发饰",
            "value": "leaf hair ornament"
          },
          {
            "text": "兔子头饰",
            "value": "bunny hair ornament"
          },
          {
            "text": "骷髅头饰",
            "value": "skull hair ornament"
          },
          {
            "text": "黄色发带",
            "value": "yellow hairband"
          },
          {
            "text": "粉色发带",
            "value": "pink hairband"
          },
          {
            "text": "蝴蝶结发带",
            "value": "bow hairband"
          },
          {
            "text": "猫头饰",
            "value": "cat hair ornament"
          },
          {
            "text": "音符发饰",
            "value": "musical note hair ornament"
          },
          {
            "text": "胡萝卜发饰",
            "value": "carrot hair ornament"
          },
          {
            "text": "紫色发带",
            "value": "purple hairband"
          },
          {
            "text": "发珠",
            "value": "hair beads"
          },
          {
            "text": "多个蝴蝶结",
            "value": "multiple hair bows"
          },
          {
            "text": "蝙蝠发饰",
            "value": "bat hair ornament"
          },
          {
            "text": "骨发饰",
            "value": "bone hair ornament"
          },
          {
            "text": "橙色发带",
            "value": "orange hairband"
          },
          {
            "text": "雪花发饰",
            "value": "snowflake hair ornament"
          },
          {
            "text": "头上有花",
            "value": "flower on head"
          },
          {
            "text": "头上戴着花冠",
            "value": "head wreath"
          }
        ]
      },
      {
        "text": "耳朵",
        "value": "耳朵",
        "chlidren": []
      },
      {
        "text": "表情",
        "value": "表情",
        "chlidren": []
      }
    ]
  },
  {
    "text": "画面",
    "value": "effect",
    "chlidren": [
      {
        "text": "效果",
        "value": "效果",
        "chlidren": [
          {
            "text": "明暗对比",
            "value": "Chiaroscuro"
          },
          {
            "text": "动态模糊",
            "value": "motion blur"
          },
          {
            "text": "电影光效",
            "value": "cinematic lighting"
          },
          {
            "text": "色差",
            "value": "chromatic aberration"
          },
          {
            "text": "闪耀效果",
            "value": "sparkle"
          },
          {
            "text": "JPEJ 压缩失真",
            "value": "jpeg artifacts"
          },
          {
            "text": "背景虚化",
            "value": "depth of field"
          },
          {
            "text": "模糊的",
            "value": "blurry"
          },
          {
            "text": "荧光",
            "value": "glowing light"
          },
          {
            "text": "神圣感顶光",
            "value": "god rays"
          },
          {
            "text": "光线追踪",
            "value": "ray tracing"
          },
          {
            "text": "反射光",
            "value": "reflection light"
          },
          {
            "text": "逆光",
            "value": "backlighting"
          },
          {
            "text": "混合",
            "value": "blending"
          },
          {
            "text": "盛开",
            "value": "bloom"
          },
          {
            "text": "色差滥用",
            "value": "chromatic aberration abuse"
          },
          {
            "text": "抖动",
            "value": "dithering"
          },
          {
            "text": "立绘阴影",
            "value": "drop shadow"
          },
          {
            "text": "胶片颗粒感/老电影过滤",
            "value": "film grain"
          },
          {
            "text": "富士色彩",
            "value": "Fujicolor"
          },
          {
            "text": "半调风格",
            "value": "halftone"
          },
          {
            "text": "图像填充",
            "value": "image fill"
          },
          {
            "text": "体现运动的线",
            "value": "motion lines"
          },
          {
            "text": "多重单色",
            "value": "multiple monochrome"
          },
          {
            "text": "视错觉",
            "value": "optical illusion"
          },
          {
            "text": "互补色",
            "value": "anaglyph"
          },
          {
            "text": "立体画",
            "value": "stereogram"
          },
          {
            "text": "扫描线",
            "value": "scanlines"
          },
          {
            "text": "剪影",
            "value": "silhouette"
          },
          {
            "text": "速度线",
            "value": " speed lines"
          },
          {
            "text": "晕影",
            "value": "vignetting"
          }
        ]
      }
    ]
  },
  {
    "text": "绘画",
    "value": "draw",
    "chlidren": [
      {
        "text": "风格",
        "value": "风格",
        "chlidren": [
          {
            "text": "超现实主义",
            "value": "Surrealism"
          },
          {
            "text": "色块画",
            "value": "Color Field painting"
          },
          {
            "text": "装饰艺术",
            "value": "Art Deco"
          },
          {
            "text": "洛可可风格",
            "value": "rococo style"
          },
          {
            "text": "高细节",
            "value": "high detail"
          },
          {
            "text": "盲盒玩具风格",
            "value": "blind box toy style"
          },
          {
            "text": "立体派未来主义",
            "value": "Cubist Futurism"
          },
          {
            "text": "概念艺术",
            "value": "Conceptual art"
          },
          {
            "text": "未来主义",
            "value": "Futurism"
          },
          {
            "text": "社会现实主义",
            "value": "Social realism"
          },
          {
            "text": "室内建筑",
            "value": "interior architecture"
          },
          {
            "text": "文艺复兴",
            "value": "Renaissance"
          },
          {
            "text": "新古典主义",
            "value": "Neoclassicism"
          },
          {
            "text": "现代",
            "value": "modern"
          },
          {
            "text": "动漫",
            "value": "anime"
          },
          {
            "text": "极简主义",
            "value": "Minimalism"
          },
          {
            "text": "浪漫主义",
            "value": "Romanticism"
          },
          {
            "text": "哥特式艺术",
            "value": "Gothic art"
          },
          {
            "text": "美国宣传海报",
            "value": "American propaganda poster"
          },
          {
            "text": "色调主义",
            "value": "Tonalism"
          },
          {
            "text": "巴洛克",
            "value": "Baroque"
          },
          {
            "text": "野兽派",
            "value": "Fauvism"
          },
          {
            "text": "表现主义",
            "value": "Expressionism"
          },
          {
            "text": "卡尔·拉尔松风格的画作",
            "value": "Carl Larsson"
          },
          {
            "text": "视错觉艺术",
            "value": "Op art"
          },
          {
            "text": "现实主义",
            "value": "Realism"
          },
          {
            "text": "当代艺术",
            "value": "Contemporary art"
          },
          {
            "text": "风俗画",
            "value": "Genre painting"
          },
          {
            "text": "构成主义",
            "value": "Constructivism"
          },
          {
            "text": "后期文艺复兴",
            "value": "Mannerism"
          },
          {
            "text": "包豪斯",
            "value": "Bauhaus"
          },
          {
            "text": "行动绘画",
            "value": "Action painting"
          },
          {
            "text": "由阿尔方斯·穆卡制作",
            "value": "by Alfons Mucha"
          },
          {
            "text": "荷兰黄金时期绘画",
            "value": "Dutch Golden Age painting"
          },
          {
            "text": "波普艺术",
            "value": "Pop art"
          },
          {
            "text": "莫奈",
            "value": "Monet"
          },
          {
            "text": "北方文艺复兴",
            "value": "Northern Renaissance"
          },
          {
            "text": "达达主义",
            "value": "Dadaism"
          },
          {
            "text": "浮世绘",
            "value": "Ukiyo-e"
          },
          {
            "text": "明亮主义",
            "value": "Luminism"
          },
          {
            "text": "抽象表现主义",
            "value": "Abstract expressionism"
          },
          {
            "text": "印象派",
            "value": "Impressionism"
          },
          {
            "text": "前拉斐尔派兄弟会",
            "value": "Pre-Raphaelite Brotherhood"
          },
          {
            "text": "古典主义",
            "value": "Classicism"
          },
          {
            "text": "吉卜力色彩",
            "value": "Ghibli-like colours"
          },
          {
            "text": "超现实主义细节画派",
            "value": "Hyperrealism"
          },
          {
            "text": "新艺术运动",
            "value": "Art Nouveau"
          },
          {
            "text": "至高主义",
            "value": "Suprematism"
          },
          {
            "text": "抽象主义",
            "value": "Abstractionism"
          },
          {
            "text": "前拉斐尔派绘画",
            "value": "pre-rephaëlite painting"
          },
          {
            "text": "动漫风格",
            "value": "anime style"
          },
          {
            "text": "后印象派",
            "value": "Post-Impressionism"
          },
          {
            "text": "野外写生",
            "value": "En plein air"
          },
          {
            "text": "点彩派",
            "value": "Pointillism"
          },
          {
            "text": "写实主义",
            "value": "Verism"
          },
          {
            "text": "扬起的拳头",
            "value": "raised fist"
          },
          {
            "text": "垃圾桶派",
            "value": "Ashcan School"
          },
          {
            "text": "皮克斯",
            "value": "Pixar"
          },
          {
            "text": "立体主义",
            "value": "Cubism"
          },
          {
            "text": "风格",
            "value": "--style "
          }
        ]
      }
    ]
  },
  {
    "text": "命令",
    "value": "command",
    "chlidren": [
      {
        "text": "摄影测试模型",
        "value": "摄影测试模型",
        "chlidren": []
      },
      {
        "text": "风格化",
        "value": "风格化",
        "chlidren": [
          {
            "text": "风格化 0",
            "value": "--stylize 0"
          },
          {
            "text": "风格化 500",
            "value": "--stylize 500"
          },
          {
            "text": "风格化 1000",
            "value": "--stylize 1000"
          },
          {
            "text": "奇怪的",
            "value": "--weird 250"
          }
        ]
      },
      {
        "text": "版本",
        "value": "版本",
        "chlidren": [
          {
            "text": "版本4",
            "value": "--version 4"
          },
          {
            "text": "版本5",
            "value": "--version 5"
          },
          {
            "text": "版本niji",
            "value": "--version niji"
          },
          {
            "text": "版本5.2",
            "value": "--version 5.2"
          }
        ]
      },
      {
        "text": "停止",
        "value": "停止",
        "chlidren": []
      },
      {
        "text": "动漫模型",
        "value": "动漫模型",
        "chlidren": [
          {
            "text": "动漫模型",
            "value": "--niji "
          }
        ]
      },
      {
        "text": "多样性",
        "value": "多样性",
        "chlidren": [
          {
            "text": "多样性",
            "value": "--chaos 0"
          },
          {
            "text": "多样性",
            "value": "--chaos 50"
          },
          {
            "text": "多样性",
            "value": "--chaos 100"
          }
        ]
      },
      {
        "text": "负面",
        "value": "负面",
        "chlidren": []
      },
      {
        "text": "重复",
        "value": "重复",
        "chlidren": [
          {
            "text": "重复 1",
            "value": "--repeat 1"
          },
          {
            "text": "重复 3",
            "value": "--repeat 3"
          }
        ]
      },
      {
        "text": "质量",
        "value": "质量",
        "chlidren": [
          {
            "text": "质量 .25",
            "value": "--quality .25"
          },
          {
            "text": "质量 .5",
            "value": "--quality .5"
          },
          {
            "text": "质量 1",
            "value": "--quality 1"
          }
        ]
      },
      {
        "text": "高清模型",
        "value": "高清模型",
        "chlidren": []
      },
      {
        "text": "风格",
        "value": "风格",
        "chlidren": [
          {
            "text": "超现实主义",
            "value": "Surrealism"
          },
          {
            "text": "色块画",
            "value": "Color Field painting"
          },
          {
            "text": "装饰艺术",
            "value": "Art Deco"
          },
          {
            "text": "洛可可风格",
            "value": "rococo style"
          },
          {
            "text": "高细节",
            "value": "high detail"
          },
          {
            "text": "盲盒玩具风格",
            "value": "blind box toy style"
          },
          {
            "text": "立体派未来主义",
            "value": "Cubist Futurism"
          },
          {
            "text": "概念艺术",
            "value": "Conceptual art"
          },
          {
            "text": "未来主义",
            "value": "Futurism"
          },
          {
            "text": "社会现实主义",
            "value": "Social realism"
          },
          {
            "text": "室内建筑",
            "value": "interior architecture"
          },
          {
            "text": "文艺复兴",
            "value": "Renaissance"
          },
          {
            "text": "新古典主义",
            "value": "Neoclassicism"
          },
          {
            "text": "现代",
            "value": "modern"
          },
          {
            "text": "动漫",
            "value": "anime"
          },
          {
            "text": "极简主义",
            "value": "Minimalism"
          },
          {
            "text": "浪漫主义",
            "value": "Romanticism"
          },
          {
            "text": "哥特式艺术",
            "value": "Gothic art"
          },
          {
            "text": "美国宣传海报",
            "value": "American propaganda poster"
          },
          {
            "text": "色调主义",
            "value": "Tonalism"
          },
          {
            "text": "巴洛克",
            "value": "Baroque"
          },
          {
            "text": "野兽派",
            "value": "Fauvism"
          },
          {
            "text": "表现主义",
            "value": "Expressionism"
          },
          {
            "text": "卡尔·拉尔松风格的画作",
            "value": "Carl Larsson"
          },
          {
            "text": "视错觉艺术",
            "value": "Op art"
          },
          {
            "text": "现实主义",
            "value": "Realism"
          },
          {
            "text": "当代艺术",
            "value": "Contemporary art"
          },
          {
            "text": "风俗画",
            "value": "Genre painting"
          },
          {
            "text": "构成主义",
            "value": "Constructivism"
          },
          {
            "text": "后期文艺复兴",
            "value": "Mannerism"
          },
          {
            "text": "包豪斯",
            "value": "Bauhaus"
          },
          {
            "text": "行动绘画",
            "value": "Action painting"
          },
          {
            "text": "由阿尔方斯·穆卡制作",
            "value": "by Alfons Mucha"
          },
          {
            "text": "荷兰黄金时期绘画",
            "value": "Dutch Golden Age painting"
          },
          {
            "text": "波普艺术",
            "value": "Pop art"
          },
          {
            "text": "莫奈",
            "value": "Monet"
          },
          {
            "text": "北方文艺复兴",
            "value": "Northern Renaissance"
          },
          {
            "text": "达达主义",
            "value": "Dadaism"
          },
          {
            "text": "浮世绘",
            "value": "Ukiyo-e"
          },
          {
            "text": "明亮主义",
            "value": "Luminism"
          },
          {
            "text": "抽象表现主义",
            "value": "Abstract expressionism"
          },
          {
            "text": "印象派",
            "value": "Impressionism"
          },
          {
            "text": "前拉斐尔派兄弟会",
            "value": "Pre-Raphaelite Brotherhood"
          },
          {
            "text": "古典主义",
            "value": "Classicism"
          },
          {
            "text": "吉卜力色彩",
            "value": "Ghibli-like colours"
          },
          {
            "text": "超现实主义细节画派",
            "value": "Hyperrealism"
          },
          {
            "text": "新艺术运动",
            "value": "Art Nouveau"
          },
          {
            "text": "至高主义",
            "value": "Suprematism"
          },
          {
            "text": "抽象主义",
            "value": "Abstractionism"
          },
          {
            "text": "前拉斐尔派绘画",
            "value": "pre-rephaëlite painting"
          },
          {
            "text": "动漫风格",
            "value": "anime style"
          },
          {
            "text": "后印象派",
            "value": "Post-Impressionism"
          },
          {
            "text": "野外写生",
            "value": "En plein air"
          },
          {
            "text": "点彩派",
            "value": "Pointillism"
          },
          {
            "text": "写实主义",
            "value": "Verism"
          },
          {
            "text": "扬起的拳头",
            "value": "raised fist"
          },
          {
            "text": "垃圾桶派",
            "value": "Ashcan School"
          },
          {
            "text": "皮克斯",
            "value": "Pixar"
          },
          {
            "text": "立体主义",
            "value": "Cubism"
          },
          {
            "text": "风格",
            "value": "--style "
          }
        ]
      },
      {
        "text": "图像权重",
        "value": "图像权重",
        "chlidren": [
          {
            "text": "图像权重",
            "value": "--iw 1"
          }
        ]
      },
      {
        "text": "测试模型",
        "value": "测试模型",
        "chlidren": []
      },
      {
        "text": "轻量放大器",
        "value": "轻量放大器",
        "chlidren": []
      }
    ]
  }
]

export default  typelist
