<template>
    <div style="background-color: #f7f7f7;" >
       <div><img src="../assets/openask/topback.svg" alt="" style="width:100%"></div>
        
       <!-- 右边的登录悬浮 -->
       <div style="position:absolute;width:240px;background: white;top: 50px;border-radius: 20px;left: 32px;color:#8e8e89">
          <div style="display:flex;padding:20px;flex-direction: column;    align-items: center;">
            <img src="../assets/image/setting4.svg" alt="" style="width:120px">
            <div>提问喜爱的创作者</div>
            <div>获取专属语音回复</div>
          </div>
        </div>
        <!-- 左边数据展示列表 -->
        <div style="display:flex;flex-flow: row wrap;justify-content: center;gap: 8px 12px;">
          <div style="padding: 43px 70px 36px 360px;box-sizing: content-box;width: 912px;">
            <div style="display: flex;">
              <div style="background:white;border-radius:12px;width:330px;height:200px;margin-right: 16px">
                <div style="    padding: 24px;">
                  <div style="display:flex;align-items: center;">
                    <div style="font-size: 24px;font-weight: 700;">开始提问</div>
                    <img src="../assets/openask/topicontab.svg" alt="" style="width:48px;height:48px;margin-left:12px">
                  </div>
                  <div style="font-size: 20px;margin-top:22px">敢于提问的人已经赚了</div>
                  <div style="font-size: 24px;margin-top:22px;font-weight: 700;">$719</div>
                </div>
              </div>
              <div style="background:white;border-radius:12px;width:330px;height:200px;margin-right: 16px">
                <div style="    padding: 24px;">
                  <div style="display:flex;align-items: center;">
                    <div style="font-size: 24px;font-weight: 700;">开始提问</div>
                    <img src="../assets/openask/topicontab.svg" alt="" style="width:48px;height:48px;margin-left:12px">
                  </div>
                  <div style="font-size: 20px;margin-top:22px">敢于提问的人已经赚了</div>
                  <div style="font-size: 24px;margin-top:22px;font-weight: 700;">$719</div>
                </div>
              </div>
              <div style="background:white;border-radius:12px;width:330px;height:200px;margin-right: 16px">
                <div style="    padding: 24px;">
                  <div style="display:flex;align-items: center;">
                    <div style="font-size: 24px;font-weight: 700;">开始提问</div>
                    <img src="../assets/openask/topicontab.svg" alt="" style="width:48px;height:48px;margin-left:12px">
                  </div>
                  <div style="font-size: 20px;margin-top:22px">敢于提问的人已经赚了</div>
                  <div style="font-size: 24px;margin-top:22px;font-weight: 700;">$719</div>
                </div>
              </div>
            </div>

            <div style="display:flex;align-items: center;justify-content: space-between;margin-top:40px">
              <div style="font-size: 24px;font-weight: 700">热门问答</div>
              <div style="font-size: 20px ;    margin-right: 10px;">更多</div>
            </div>

            <div style="display:flex;justify-content: space-between;margin-top:20px">
              <div style="background:white;border-radius:12px;width:450px;height:260px">
              </div>
              <div style="background:white;border-radius:12px;width:450px;height:260px">
              </div>
            </div>
          </div>
        </div>
        
    </div>
    
  </template>
  
  <script>
  
  export default {
    name: 'App',
    components: {
      
    },
    watch: {
    },
    data(){
      return {
       
      }
    },  
    setup(){
    
    },
    created() {
    
    },
    mounted() {
       
      },
    methods:{
      
    }
  }
  </script>
  
  <style lang="scss">
  
  html,body,#app{ height: 100%; margin: 0; padding: 0; }
  </style>
  