import axios from "../utils/request.js";

export default {
  gpt3Request(data) {
    return axios.postFile("/api/v1/chat/stream", data);
  },
  gpt3ChatNewsRequest(data) {
    return axios.postFile("/api/v1/chat/imgAnalysisStream", data);
  },
  googleRequest(data) {
    return axios.postFile("/api/v1/chat/googlestream", data);
  },
  bingRequest(data) {
    return axios.postFile("/api/v1/chat/bingstream", data);
  },

  gpt3CImageRequest(data) {
    return axios.postFile("/api/v1/image/createStream", data);
  },
  gpt3VoiceRequest(data) {
    return axios.postFile("/api/v1/chat/streamVoice", data);
  },
  completionStream() {
    return axios.postFile("/api/v1/chat/completionStreamSync");
  },

  imagefileRequest(data) {
    // data = JSON.stringify(data)
    return axios.postform("/api/v1/chat/voiceGetUrl", data);
  },

  imageRequest(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/historyImageMsg", data);
  },

  streamImage(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/image/streamImage", data);
  },
  unlogin() {
    return axios.get("/api/v1/user/Logout");
  },

  // login(data) {
  //   return axios.get('/api/v1/testLogin',data)
  // },
  robotcategory() {
    return axios.get("/api/v1/prompts/category");
  },
  joinrobort(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/prompts/join", data);
  },
  addrobort(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api//v1/prompts/create", data);
  },
  chathistory() {
    return axios.get("/api/v1/chat/session");
  },
  getcode(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/login/SendBindMobileSms", data);
  },
  myrobort() {
    return axios.get("/api/v1/prompts/list");
  },

  getchattype() {
    return axios.get("/api/v1/ChatType");
  },

  translateMsg(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/translateInput", data);
  },

  historyMsg(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/historyMsg", data);
  },

  googlehistoryMsg(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/googlehistoryMsg", data);
  },
  binghistoryMsg(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/binghistoryMsg", data);
  },

  historyVoiceMsg(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/historyVoiceMsg", data);
  },

  historyChatNewMsg(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/chat/histroyAImg", data);
  },

  historyCreatImage(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/image/histroyCImage", data);
  },

  login(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/login/PhoneLogin", data);
  },
  qrlogin() {
    return axios.get("/api/v1/WxWebQrCode");
  },

  tokenLogin(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/login/TokenLogin", data);
  },

  userinfo() {
    return axios.get("/api/v1/user/info");
  },
  checkqrcode(data) {
    return axios.get("/api/v1/login/WxWebLoginCheck?scene=" + data);
  },

  CreatImage(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/image/createImage", data);
  },

  getgoodsid(data) {
    return axios.get("/api/v1/order/goods/list", data);
  },
  bindmobile(data) {
    return axios.postjson("/api/v1/user/BindMobile", data);
  },
  
  wxpaycode(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/order/WechatCreateOrder", data);
  },
  alipaycode(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/order/AlipayCreateOrder", data);
  },
  lemonpaycode(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/order/LemonCreateOrder", data);
  },
  checkorder(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/order/OrderState", data);
  },

  getEmbeddingInput(data) {
    data = JSON.stringify(data);
    return axios.postjson("/api/v1/file/getEmbeddingInput", data);
  },
};
