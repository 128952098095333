<template>
	<div class="outdiv" style="">
		<div style="display:flex;    align-items: center;margin-bottom: 10px;">
			<div style="width:28px;    display: flex;">
				<img v-if="audioStatus == 'run'" src="../assets/image/sendend.svg" alt="" @click="playAudio" style="cursor: pointer;">
				<img v-else src="../assets/bofanginfo.svg" alt="" @click="playAudio" style="cursor: pointer;">
			</div>
			
			<div style="margin:0 12px">{{videoStart}}</div>
			<div class="block" style="width:700px">
				<el-slider v-model="value"></el-slider>
			</div>
			<div style="margin:0 12px">{{videoEnd}}</div>
			<audio @timeupdate="updateProgress" controls ref="audioRef" style="display: none" @canplay="canplaysong">
				<source  :src="voiceurl" type="audio/mpeg" />您的浏览器不支持音频播放
			</audio>
		

		</div>
		<div style="border-bottom:1px solid rgb(255 255 255 / 10%);width: 100%;"></div>
		<div v-if="!showtranslate" style="margin-top: 16px;border-bottom:1px solid rgb(255 255 255 / 10%);padding-bottom: 16px;position: relative;">
			<div v-if="showmosaic" style="background-color:grey;height:100%;width:100%;position:absolute;filter: blur(2px);opacity: 0.99;"></div>
			<div style="padding:5px">{{content}}</div>
			
		</div>
		<div v-else style="margin-top: 16px;border-bottom:1px solid rgb(255 255 255 / 10%);padding-bottom: 16px;position: relative;">
			<div v-if="showmosaic" style="background-color:grey;height:100%;width:100%;position:absolute;filter: blur(2px);opacity: 0.99;"></div>
			<div style="padding:5px">{{translatecontent}}</div>
		</div>
		<div style="display:flex;margin-top:10px;">
			<button class="midtypebtnnew" @click="showmosaic = !showmosaic"><img src="../assets/image/mohu.svg" alt="" class="btnimgnew" style="margin-left:0" >模糊</button>
			<button class="midtypebtnnew" @click="showtranslate= false"><img src="../assets/image/yuanwen.svg" alt="" class="btnimgnew" style="margin-left:0" >原文</button>
			<button class="midtypebtnnew" @click="fanyi()" ><img src="../assets/image/fanyi.svg" alt="" class="btnimgnew" style="margin-left:0" >翻译</button>
		</div>
	</div>
</template>
  
  <script>
  import api from '../api/api';
  import { ElMessage } from 'element-plus'
  export default {
	props:{
		file: {},
	},
	data() {
	  return {
		showmosaic:false,
		showtranslate:false,
		translatecontent:"",
		id:0,
		audioStatus: 'stop',
		videoStart: '00:00',
		value: 0,
		duration:0,
		videoEnd: '00:00',
		voiceurl:'',
		content:'',
	  }
	},
	mounted(){
		console.log( this.file,283928)
		this.voiceurl =  this.file.voiceUrl
		this.content = this.file.content
		if(this.file.id){
			this.id = this.file.id
		}
	},
	methods:{
		fanyi(){
			var that = this
			if(that.translatecontent != ""){
				that.showtranslate = !that.showtranslate
				return
			}
		
			ElMessage({
				showClose: true,
				message: '翻译中',
				type: 'success',
			})

			that.showtranslate = true
			console.log(123123)
			let data = {}
			data.id = this.id
			data.input = this.file.content
			data.type = "speech"
			api.translateMsg(data).then((res)=>{
                that.translatecontent = res.data.content

				ElMessage({
					showClose: true,
					message: '翻译成功',
					type: 'success',
				})
			})
		},
		canplaysong(){
			if(this.$refs.audioRef){
				this.duration = parseInt(this.$refs.audioRef.duration)
			this.videoEnd = this.transTime(this.duration)

			}
			// this.duration  = this.$refs.audioRef.duration
		},
		//播放暂停控制
		playAudio () {
			let recordAudio = this.$refs.audioRef; //获取audio元素
			if (recordAudio.paused) {
			
				if(localStorage.getItem("voicestatus") == 1){
					ElMessage({
						showClose: true,
						message: '有录音正在播放',
						type: 'error',
					})
					return
				}
				recordAudio.play();
				this.audioStatus = "run"
				localStorage.setItem("voicestatus",1)
			} else {
				recordAudio.pause();
				this.audioStatus = "stop"
				localStorage.setItem("voicestatus",0)
			}
		},
		updateProgress (e) {
			var value = e.target.currentTime / this.duration
			this.value = value * 100
			if (e.target.currentTime > this.duration) {
				this.audioStatus = 'stop'
				localStorage.setItem("voicestatus",0)
				this.value = 0
				this.videoStart = this.transTime(0)
				return
			}
			this.value = value * 100
			this.videoStart = this.transTime(this.$refs.audioRef.currentTime)
		},
		/**
		 * 音频播放时间换算
		 * @param {number} value - 音频当前播放时间，单位秒
		 */
		transTime (value) {
			var time = "";
			var h = parseInt(value / 3600);
			value %= 3600;
			var m = parseInt(value / 60);
			m = m < 10 ? '0' + m : m
			var s = parseInt(value % 60);
			s = s < 10 ? '0' + s : s
			time = m + ":" + s;
			return time;
		},
		// 进度条
		onChange (val) {
			let recordAudio = this.$refs.audioRef; //获取audio元素
			if (!recordAudio.paused || recordAudio.currentTime != 0) {
				recordAudio.currentTime = recordAudio.duration * val / 100
				this.videoStart = this.transTime(val / 100 * this.duration)
			}
		}
	}
  }
  </script>

<style scoped>
.outdiv{
	width: 852px;
	background-color: rgb(255 255 255 / 15%) !important;
    border-color: #2b2f30;
	color: #fff !important;
	letter-spacing: 1px;
    border-width: 4px;
	font-size: 14px;
    position: relative;
    padding: 12px 16px;
    line-height: 26px;
    margin-right: 200px;
    margin-left: 30px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	border-top-right-radius: 16px;
    border: 1px solid #3E4447;
}
::v-deep .el-slider__bar {
	height:1px;
	background-color:#fff
}
::v-deep .el-slider__runway{
	height:1px;
	background-color:#626469
}
::v-deep .el-slider__button{
	height: 8px;
    width: 8px;
	top: -2px;
	position: relative;
	border: solid 2px #fff
}
.midtypebtnnew{
	letter-spacing: 1px;
	display: flex;
    margin-right: 12px;
	margin-top: 5px;
    align-items: center;
    background: rgb(255 255 255 / 30%);
    border-radius: 22px !important;
    box-shadow: none !important;
    border-color: transparent !important;
    width: 82px;
    height: 32px;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}
.btnimgnew{
	width: 16px;
    height: 16px;
    margin-right: 7px;
}





</style>